import { PropsWithChildren } from 'react';
import { Box, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';

interface Props {
  title?: string;
  sx?: SxProps<Theme>;
  className?: string;
  withWrapperBlock?: boolean;
  variant?: 'normal' | 'condensed';
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'unset',

    padding: '12px 16px',

    '&.normal': {
      borderBottom: `1px solid ${grey[300]}`,
    },

    '&.condensed': {
      paddingBottom: 0,
    },
  },
  title: {
    '&.normal': {},

    '&.condensed': {
      fontWeight: 600,
    },
  },
}));

export const CardHeader = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const {
    title,
    sx,
    className,
    withWrapperBlock = true,
    variant = 'normal',
    children,
  } = props;

  const getHeaderVariant = () => {
    switch (variant) {
      case 'normal':
        return 'h6';
      case 'condensed':
      default:
        return 'body1';
    }
  };

  return (
    <Paper
      elevation={0}
      className={clsx(classes.paper, variant, className)}
      sx={sx}
    >
      {title && (
        <Typography
          variant={getHeaderVariant()}
          className={clsx(classes.title, variant)}
        >
          {title}
        </Typography>
      )}
      {withWrapperBlock ? <Box>{children}</Box> : { children }}
    </Paper>
  );
};
