import { TenantCreateDTO } from 'api/payments-client';
import { useState } from 'react';

import { Button } from '@mui/material';

import { FormDialog } from 'components';
import { AddTenantForm } from 'forms';
import { useRoles } from 'hooks';
import { FormikValues } from 'formik';

type Props = {
  onSubmit: (arg0: TenantCreateDTO) => Promise<void>;
};

export const AddTenantWidget = ({ onSubmit }: Props) => {
  const { isViewOnly } = useRoles();
  const [isModalOpen, setModalOpen] = useState(false);

  if (isViewOnly) return null;

  return (
    <div>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Add
      </Button>
      <FormDialog
        header="Add a new tenant"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={async (values: FormikValues, helpers) => {
          try {
            await onSubmit(values as TenantCreateDTO);
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          } finally {
            helpers?.setSubmitting(false);
          }
        }}
      >
        <AddTenantForm />
      </FormDialog>
    </div>
  );
};
