import { parse, stringify, IParseOptions, IStringifyOptions } from 'qs';

const parseOptions: IParseOptions = {
  ignoreQueryPrefix: true,
  strictNullHandling: true,
  decoder: (str, decoder, charset) => {
    const strWithoutPlus = str.replace(/\+/g, ' ');
    if (charset === 'iso-8859-1') {
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    if (/^(\d+|\d*\.\d+)$/.test(str)) {
      return parseFloat(str);
    }

    if (str.length === 0) {
      return;
    }

    const keywords: any = {
      true: true,
      false: false,
      null: null,
      undefined,
    };

    if (str in keywords) {
      return keywords[str];
    }

    try {
      return decodeURIComponent(strWithoutPlus);
    } catch (e) {
      return strWithoutPlus;
    }
  },
};

const stringifyOptions: IStringifyOptions = {
  arrayFormat: 'brackets',
  strictNullHandling: true,
};

export const parseQuery = <T extends object>(query: string) => {
  return parse(query, parseOptions) as T;
};

export const stringifyQuery = (obj: any) => stringify(obj, stringifyOptions);
