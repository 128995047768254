import { toast } from 'react-toastify';

import { Api, Api as LedgerApi } from './ledger-client';
import { Api as PaymentsApi } from './payments-client';
import { baseUrl } from 'wrappers/ConfigLoaderWrapper';
import { stringifyQuery } from 'utils';

const paymentsApiClient = new PaymentsApi({
  baseURL: baseUrl,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

paymentsApiClient.instance.interceptors.response.use(
  undefined,
  (error: { response: { data: { detail: string | string[] } } }) => {
    const detail = error.response?.data?.detail;
    let err = '';
    if (Array.isArray(detail)) {
      detail.forEach((e) => {
        toast.error(e);
        err += `${e} `;
      });
    } else {
      toast.error(detail);
      err = detail;
    }
    throw err;
  },
);

function createLedgerApiClient() {
  const ledgerApiClient: Api<{ token: string }> = new LedgerApi({
    baseURL: window.appConfig.ledgerBaseUrl,
    paramsSerializer: stringifyQuery,
    securityWorker: (securityData: { token: string } | null) => {
      if (securityData) {
        return {
          headers: {
            Authorization: `Bearer ${securityData.token}`,
          },
        };
      }
    },
  });

  ledgerApiClient.instance.interceptors.response.use(
    undefined,
    (error: { response: { data: { detail: string | string[] } } }) => {
      const detail = error.response?.data?.detail;
      let err = '';
      if (Array.isArray(detail)) {
        detail.forEach((e) => {
          toast.error(e);
          err += `${e} `;
        });
      } else {
        toast.error(detail);
        err = detail;
      }
      throw err;
    },
  );

  window.ledgerApiClient = ledgerApiClient;
}

export { paymentsApiClient, createLedgerApiClient };
