import { PaymentDTO } from 'api/payments-client';
import useSWR from 'swr';

import { paymentsApiClient } from '../api';

export const usePaymentsSearch = (search = '', tenantId: string) => {
  const { data, error } = useSWR(
    search === '' ? null : [search],
    async (search: string) => {
      const { data: deals } = await paymentsApiClient.api.paymentControllerSearch({
        query: search,
        tenantId
      });
      return deals;
    },
  );

  return {
    data: (data ?? []) as Array<PaymentDTO>,
    isLoading: !error && !data,
    isError: error,
  };
};
