import { paymentsApiClient } from 'api';
import { BankStatementEntity } from 'api/payments-client';
import { Card, Title } from 'components';
import { useQuery } from 'hooks/useQuery';
import { ParsedUrlQuery } from 'querystring';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from 'use-deep-compare-effect';

import { BankStatementsTable } from './BankStatementsTable';

export const BankStatementsPage = () => {
  const { pushQuery, query } = useQuery([
    'pageSize',
    'pageIndex',
    'statementId',
    'iban',
    'openingBalance',
    'closingBalance',
    'fromDate',
    'toDate',
  ]);
  const pageSize = !isNaN(Number(query.pageSize)) ? Number(query.pageSize) : 25;
  const pageIndex = !isNaN(Number(query.pageIndex))
    ? Number(query.pageIndex)
    : 0;

  const [rows, setRows] = useState<BankStatementEntity[]>([]);
  const [rowsCount, setRowsCount] = useState<number>(0);
  const [filters, setFilters] = useState<Record<string, string[]>>();

  const fetchBankStatements = useAsyncCallback(
    async (query: ParsedUrlQuery = {}) => {
      const { data } =
        await paymentsApiClient.api.ebicsControllerGetBankStatements({
          ...query,
          openingBalance: query.openingBalance
            ? +query.openingBalance * 100
            : undefined,
          closingBalance: query.closingBalance
            ? +query.closingBalance * 100
            : undefined,
        });
      setRows(data?.items ?? []);
      setRowsCount(data?.meta?.totalItems ?? 0);
      setFilters(data?.filters);
    },
  );

  const handleFiltersChange = async (data: {
    filters: Array<{ id: string; value: string | string[] }>;
    pageSize: number;
    pageIndex: number;
  }) => {
    const query = {
      ...data.filters
        .map((e) => ({
          [e.id]: Array.isArray(e.value) ? e.value.join(',') : e.value,
        }))
        .reduce((acc, val) => ({ ...acc, ...val }), {}),
      pageSize: data.pageSize.toString(),
      pageIndex: data.pageIndex.toString(),
    };
    pushQuery(query);
  };

  useDeepCompareEffect(() => {
    if (isEmpty(query)) {
      pushQuery({
        pageSize: '25',
        pageIndex: '0',
      });
    } else {
      fetchBankStatements.execute(query);
    }
  }, [query]);

  useDeepCompareEffectNoCheck(() => {
    if (filters) pushQuery({ ...query, ...filters });
  }, [filters]);

  return (
    <Card>
      <Card.Body>
        <Title name="BankStatements"></Title>

        <BankStatementsTable
          rows={rows}
          onFiltersChange={handleFiltersChange}
          count={rowsCount}
          initialFilters={Object.keys(query)
            .filter((key) => key !== 'pageSize' && key !== 'pageIndex')
            .map((key) => {
              const value = query[key];
              return {
                id: key,
                value: Array.isArray(value) ? value : value?.split(','),
              };
            })}
          pageSize={pageSize}
          pageIndex={pageIndex}
          quickFilters={{ id: '', values: [] }}
        />
      </Card.Body>
    </Card>
  );
};
