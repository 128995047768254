import { paymentsApiClient } from 'api';
import { Card, Chip, Input, Select, Title } from 'components';
import { Field, Form, Formik } from 'formik';
import { useAsync } from 'react-async-hook';
import { useHistory, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { downloadPlain, toTwoDecimals } from 'utils';
import { PaymentStatus } from 'types/payment';
import { PaymentDTO } from 'api/payments-client';
import { useRoles } from 'hooks';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { useState } from 'react';
import { moment } from 'types';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  block: {
    position: 'relative',
  },
}));

export const PaymentsDetailsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isSuperAdmin } = useRoles();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const [extraExpand, setExtraExpand] = useState<boolean>(false);

  const {
    result: payment,
    loading,
    execute,
    error,
  } = useAsync(async () => {
    const { data } = await paymentsApiClient.api.paymentControllerFindOne(id);
    return data;
  }, [id]);

  if (loading) {
    return <CircularProgress size={40} />;
  }

  if (error || !payment) {
    return <div>Payment not found</div>;
  }
  return (
    <Card>
      <Card.Body>
        <Title name={`Payment ${payment.id}`} />
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Formik
              enableReinitialize
              initialValues={
                {
                  ...payment,
                  transferDueDate: payment?.transferDueDate
                    ? moment(payment.transferDueDate).format('DD.MM.YYYY')
                    : '',
                  amount: toTwoDecimals(payment.amount),
                  lastUpdateDate: payment?.lastUpdateDate
                    ? moment(payment.lastUpdateDate).format(
                        'DD.MM.YYYY HH:mm:ss',
                      )
                    : '-',
                  meta: {
                    ...payment.meta,
                    created: payment.meta.created
                      ? moment(payment.meta.created).format(
                          'DD.MM.YYYY HH:mm:ss',
                        )
                      : '',
                  },
                } as unknown as PaymentDTO
              }
              onSubmit={async (values: PaymentDTO) => {
                await paymentsApiClient.api.paymentControllerUpdatePayment(
                  payment.id,
                  {
                    ...values,
                    amount: Math.round(+values.amount * 100),
                    transferDueDate: values.transferDueDate
                      ? moment(
                          values.transferDueDate,
                          'DD.MM.YYYY',
                        ).toISOString()
                      : undefined,
                  },
                );
                await execute();
              }}
            >
              {({ values }) => (
                <Form>
                  <Paper elevation={3}>
                    <Typography variant="subtitle1">
                      Payments details
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} className={classes.block}>
                        <List>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="id"
                              name="id"
                              label="ID"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="meta.created"
                              name="meta.created"
                              label="Creation date"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="lastUpdateDate"
                              name="lastUpdateDate"
                              label="Last update"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                              value={`${values.lastUpdateDate ?? '-'} / ${
                                values.lastUpdateBy ?? '-'
                              }`}
                            />
                          </ListItem>

                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="virtualAccountId"
                              name="virtualAccountId"
                              label="Virtual Account ID"
                              labelDirection="row"
                              component={Chip}
                              md={4}
                              className={classes.input}
                              onClick={(value: string) =>
                                history.push(
                                  `/tenants/${tenantId}/virtual-accounts/${value}`,
                                )
                              }
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="type"
                              name="type"
                              label="Type"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="reference"
                              name="reference"
                              label="Reference"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled={!isSuperAdmin}
                              id="status"
                              name="status"
                              label="Status"
                              labelDirection="row"
                              component={Select}
                              values={Object.values(PaymentStatus).map((e) => ({
                                value: e,
                                label: e,
                              }))}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          {values.status === PaymentStatus.DECLINED && (
                            <ListItem divider disableGutters>
                              <Field
                                inputProps={{ maxLength: 400 }}
                                multiline
                                id="declinedReason"
                                name="declinedReason"
                                label="Declined Reason"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                          )}
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="method"
                              name="method"
                              label="Method"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled={
                                !isSuperAdmin ||
                                payment.status !== PaymentStatus.OPEN
                              }
                              id="amount"
                              name="amount"
                              label="Amount"
                              labelDirection="row"
                              component={Input}
                              InputProps={{
                                inputComponent: NumberFormat,
                                inputProps: {
                                  allowNegative: false,
                                  decimalScale: 2,
                                  fixedDecimalScale: true,
                                },
                              }}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="currency"
                              name="currency"
                              label="Currency"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              id="bookingText"
                              name="bookingText"
                              label="Booking Text"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled
                              name="extra"
                              multiline={extraExpand}
                              label="Additional Information"
                              labelDirection="row"
                              component={Input}
                              value={
                                values.extra &&
                                JSON.stringify(
                                  values.extra,
                                  null,
                                  extraExpand ? 2 : undefined,
                                )
                              }
                              md={4}
                              className={classes.input}
                            ></Field>{' '}
                            {values.extra && (
                              <IconButton
                                onClick={() => {
                                  setExtraExpand(!extraExpand);
                                }}
                              >
                                {extraExpand ? (
                                  <MdOutlineArrowDropUp />
                                ) : (
                                  <MdOutlineArrowDropDown />
                                )}
                              </IconButton>
                            )}
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              id="comment"
                              name="comment"
                              label="Comment"
                              multiline
                              inputProps={{ maxLength: 400 }}
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          <ListItem divider disableGutters>
                            <Field
                              disabled={
                                !isSuperAdmin ||
                                payment.status !== PaymentStatus.OPEN
                              }
                              id="transferDueDate"
                              name="transferDueDate"
                              label="Transfer Due Date"
                              labelDirection="row"
                              component={Input}
                              md={4}
                              className={classes.input}
                            />
                          </ListItem>
                          {values.description && (
                            <ListItem divider disableGutters>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  downloadPlain(
                                    values.description,
                                    `${moment().format('DDMMYYYY')}_${
                                      values.id
                                    }`,
                                  )
                                }
                              >
                                Download raw data
                              </Button>
                            </ListItem>
                          )}

                          {isSuperAdmin && (
                            <ListItem divider disableGutters>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Save
                              </Button>
                            </ListItem>
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  </Paper>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
