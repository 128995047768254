import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Props<T> = {
  pushQuery: (paramsObj: T) => void;
  query: T;
};

const objectToRecord = (obj: any) => {
  const res: Record<string, string> = {};

  for (let n in obj) {
    const v = obj[n];
    if (v !== undefined && v !== null) res[n] = obj[n].toString();
  }

  return res;
};

export const useQuery = <T = any>(deps: Array<string>): Props<T> => {
  const history = useHistory();
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);

    return {
      pushQuery: (paramsObj) => {
        history.push({
          ...history,
          search: new URLSearchParams(objectToRecord(paramsObj)).toString(),
        });
      },
      query: deps.reduce((acc, query) => {
        const value = params.get(query);
        return {
          ...acc,
          ...(value ? { [query]: value } : {}),
        };
      }, {}) as T,
    };
  }, [search, deps, history]);
};
