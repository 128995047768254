import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import React from 'react';
import { CgClose } from 'react-icons/cg';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    values: FormikValues,
    formikHelpers?: FormikHelpers<FormikValues>,
  ) => void;
  size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  header?: string;
  children: JSX.Element | JSX.Element[];
  initialValues?: FormikValues;
  cancelBtnName?: string;
  confirmBtnName?: string;
};

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer',
    fontSize: '32px',
    color: 'rgba(0, 0, 0, 0.33)',
  },
  actions: {
    paddingBottom: '0',
  },
});

export const FormDialog = ({
  isOpen,
  onClose,
  onSave,
  size = 'md',
  header,
  children,
  initialValues = {},
  cancelBtnName = 'Cancel',
  confirmBtnName = 'Confirm',
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth={size}>
      <DialogTitle>
        <div>{header}</div>
        <CgClose className={classes.closeIcon} onClick={() => onClose()} />
      </DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={(values, helpers) => {
            onSave(values, helpers);
          }}
          initialValues={initialValues}
        >
          {({ isSubmitting }) => (
            <Form>
              {React.Children.map(children, (item) => item)}
              <DialogActions className={classes.actions}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={onClose}
                >
                  {cancelBtnName}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {confirmBtnName}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
