import { VirtualAccountEntity } from 'api/payments-client';
import { Table } from 'components';
import { CellProps } from 'react-table';

import { Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';

type VirtualAccountType = { [key: string]: any } & VirtualAccountEntity;

interface FilterFragment {
  id: string;
  value: any;
}

interface VirtualAccountsTableProps {
  rows: VirtualAccountEntity[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value?: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize: number;
  pageIndex: number;
  count: number;
  autocomplete: JSX.Element;
}

export function VirtualAccountsTable(props: VirtualAccountsTableProps) {
  const history = useHistory();

  const columns = [
    {
      Header: 'Name',
      accessor: 'id',
      Filter: undefined,
      Cell: function Cell({
        row: { original },
      }: CellProps<VirtualAccountType>) {
        return (
          <Chip
            label={original.id}
            onClick={() => history.push(`virtual-accounts/${original.id}`)}
          />
        );
      },
    },
    {
      Header: 'Organization Name',
      accessor: 'organizationName',
      Filter: undefined,
    },
    {
      Header: 'Email',
      accessor: 'contact',
      Filter: undefined,
      Cell: function Cell({
        row: { original },
      }: CellProps<VirtualAccountType>) {
        return <span>{original.contact?.email}</span>;
      },
    },
    {
      Header: 'IBAN',
      accessor: 'bankAccount',
      Filter: undefined,
      Cell: function Cell({
        row: { original },
      }: CellProps<VirtualAccountType>) {
        return (
          <>
            {original.bankAccounts?.map((e) => (
              <div key={e.id}>
                {e.currency} {e.iban}
              </div>
            )) ?? ''}
          </>
        );
      },
    },
  ];

  return (
    <Table<VirtualAccountType>
      name="Virtual Accounts Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.rows}
      columns={columns}
      count={props.count}
      autocomplete={props.autocomplete}
    />
  );
}
