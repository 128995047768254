import { FunctionComponent } from 'react';

import { Card, Props } from './Card';
import { CardBody } from './CardBody';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';

interface ICard extends FunctionComponent<Props> {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Footer: typeof CardFooter;
}

const CardComponent: ICard = Card as ICard;
CardComponent.Header = CardHeader;
CardComponent.Body = CardBody;
CardComponent.Footer = CardFooter;

export { Card, CardHeader, CardBody, CardFooter };
export default CardComponent;
