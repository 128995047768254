import { FocusEvent, useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { withFormLabel } from './withFormLabel';
import { Grid } from '@mui/material';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error' | 'onChange'> & {
    error?: boolean;
    min?: number;
    max?: number;
    onChange: (id: string, value: string | number) => void;
    leftId: string;
    rightId: string;
    dividedby100: boolean;
  };

const InputComponent = ({
  disabled,
  multiline,
  field,
  min,
  max,
  error = false,
  form,
  leftId,
  rightId,
  dividedby100,
  onBlur,
  onChange,
  ...props
}: Props): JSX.Element => {
  const [leftValue, rightValue] = useMemo(
    () => [
      form.values[leftId] && dividedby100
        ? +form.values[leftId] / 100
        : form.values[leftId],
      form.values[rightId] && dividedby100
        ? +form.values[rightId] / 100
        : form.values[rightId],
    ],
    [dividedby100, leftId, rightId, form.values],
  );
  const onInputChange = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(id, dividedby100 ? +e.target.value * 100 : e.target.value);
    },
    [dividedby100, onChange],
  );

  return (
    <Grid container direction="row" wrap="nowrap">
      <TextField
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        inputProps={{
          min,
          max,
        }}
        {...field}
        {...props}
        value={leftValue ?? ''}
        onChange={onInputChange(leftId)}
        onBlur={onBlur}
        InputProps={{
          ...props.InputProps,
        }}
      />
      <TextField
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        inputProps={{
          min,
          max,
        }}
        {...field}
        {...props}
        value={rightValue ?? ''}
        onChange={onInputChange(rightId)}
        onBlur={onBlur}
        sx={{
          marginLeft: '-1px',
        }}
        InputProps={{
          ...props.InputProps,
        }}
      />
    </Grid>
  );
};

export const RangeInput = withFormLabel(
  ({ field, form, leftId, rightId, ...props }: Props) => {
    const onChange = useCallback(
      (id: string, value: string | number) => {
        if (form.setFieldValue) form.setFieldValue(id, value);
        if (props.onChange) props.onChange(id, value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [form.setFieldValue, props.onChange],
    );

    const onBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (field.onBlur) field.onBlur(e);
        if (props.onBlur) props.onBlur(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onBlur, props.onBlur],
    );

    return (
      <InputComponent
        field={field}
        form={form}
        {...props}
        leftId={leftId}
        rightId={rightId}
        onBlur={onBlur}
        onChange={onChange}
      />
    );
  },
);
