import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { Loader } from '../components';

type Props = {
  children: ReactNode;
};

const AuthWrapper = withAuthenticationRequired(
  ({ children }: Props) => {
    return <>{children}</>;
  },
  {
    onRedirecting: Loader,
  },
);

export default AuthWrapper;
