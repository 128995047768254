import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { Field, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { useAsyncCallback } from 'react-async-hook';
import { makeStyles } from '@mui/styles';

import { PaymentCreateDTO } from 'api/payments-client';
import { paymentsApiClient } from 'api';
import { Input, LazySelect, Select } from 'components';
import { notEmpty } from 'utils/validation';
import { PaymentMethod, PaymentType } from 'types/payment';
import theme from 'config/theme';

interface Props {
  tenantId: string;
  virtualAccountDisabled?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    select: {
      width: '220px',
      '& > div:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  }),
  { defaultTheme: theme },
);

export const AddPaymentForm = ({ tenantId, virtualAccountDisabled }: Props) => {
  const classes = useStyles();
  const formik = useFormikContext<PaymentCreateDTO>();

  const {
    result: virtualAccounts,
    loading: virtualAccountsLoading,
    execute: loadVirtualAccounts,
  } = useAsyncCallback(async (search: string) => {
    const { data: deals } =
      await paymentsApiClient.api.getManyBaseVirtualAccountControllerVirtualAccountEntity(
        {
          s: `{"$and": [{"tenant.id": {"$eq": "${tenantId}"}}, {"$or": [{"id": {"$contL": "${search}"}}, {"contact.firstName": {"$contL": "${search}"}}, {"contact.lastName": {"$contL": "${search}"}}]}]}`,
          limit: 10,
        },
      );

    const items = Array.isArray(deals) ? deals : deals.data;

    return (
      items.map((i) => ({
        label: i.id,
        value: i.id,
      })) ?? []
    );
  });

  const referenceDisabled = useMemo(() => {
    if (
      formik.values.type === PaymentType.PAYIN &&
      formik.values.method === PaymentMethod.BANK_TRANSFER
    ) {
      let reference = 'Payin ';

      if (formik.values.virtualAccountId) {
        reference += `${formik.values.virtualAccountId}: `;
      }

      if (formik.values.amount) {
        if (formik.values.currency) reference += `${formik.values.currency} `;
        reference += `${formik.values.amount}`;
      }

      if (reference.length) {
        reference = reference.trim() + ' - ';
      }

      reference += Date.now();

      formik.setFieldValue('reference', reference);
      return false;
    }

    formik.setFieldValue('reference', '');
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.type,
    formik.values.method,
    formik.values.virtualAccountId,
    formik.values.currency,
    formik.values.amount,
    formik.setFieldValue,
  ]);

  const investorPlaceholder = virtualAccountsLoading
    ? 'Loading'
    : virtualAccounts?.length === 0
    ? 'No available investors'
    : undefined;

  const options = useMemo(() => {
    if (
      [PaymentMethod.LSV, PaymentMethod.DEBIT_DIRECT].includes(
        formik.values.method as PaymentMethod,
      )
    ) {
      formik.setFieldValue('type', 'PAYIN');
      return [{ value: 'PAYIN', label: 'PAYIN' }];
    }
    formik.setFieldValue('type', 'PAYOUT');
    return [
      { value: 'PAYIN', label: 'PAYIN' },
      { value: 'PAYOUT', label: 'PAYOUT' },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.method, formik.setFieldValue]);

  return (
    <>
      <Grid container spacing={3} marginBottom={3}>
        <Grid item md={6}>
          <Field
            required
            id="virtualAccountId"
            name="virtualAccountId"
            label="Virtual Account"
            labelDirection="row"
            component={LazySelect}
            loadOptions={loadVirtualAccounts}
            validate={notEmpty}
            className={classes.select}
            placeholder={investorPlaceholder}
            menuPosition="absolute"
            menuPortalTarget={document.body}
            isDisabled={virtualAccountDisabled}
            value={
              virtualAccountDisabled
                ? {
                    value: formik.values.virtualAccountId,
                    label: formik.values.virtualAccountId,
                  }
                : undefined
            }
            styles={{
              menuPortal: (props: CSSProperties) => ({
                ...props,
                'z-index': 99999,
              }),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="type"
            name="type"
            label="Type"
            labelDirection="row"
            component={Select}
            values={options}
            validate={notEmpty}
          />
        </Grid>

        <Grid item md={6}>
          <Field
            required
            id="method"
            name="method"
            label="Method"
            labelDirection="row"
            component={Select}
            values={Object.values(PaymentMethod).map((e) => ({
              value: e,
              label: e,
            }))}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="currency"
            name="currency"
            label="Currency"
            labelDirection="row"
            component={Select}
            values={[
              { value: 'CHF', label: 'CHF' },
              { value: 'EUR', label: 'EUR' },
            ]}
            validate={notEmpty}
          />
        </Grid>

        <Grid item md={6}>
          <Field
            required
            id="amount"
            name="amount"
            label="Amount"
            labelDirection="row"
            component={Input}
            InputProps={{
              inputComponent: NumberFormat,
              inputProps: {
                allowNegative: false,
                decimalScale: 2,
                fixedDecimalScale: true,
              },
            }}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required={!referenceDisabled}
            id="reference"
            name="reference"
            label="Pay-in matching reference"
            labelDirection="row"
            component={Input}
            validate={referenceDisabled ? undefined : notEmpty}
            disabled={referenceDisabled}
          />
        </Grid>

        <Grid item md={6}>
          <Field
            id="transferDueDate"
            name="transferDueDate"
            label="Transfer Due Date"
            labelDirection="row"
            type="date"
            component={Input}
            placeholder="YYYY-MM-DD"
          />
        </Grid>
        <Grid item md={6}>
          <Field
            id="bookingText"
            name="bookingText"
            label="Booking Text"
            labelDirection="row"
            component={Input}
          />
        </Grid>

        <Grid item md={6}>
          <Field
            id="comment"
            name="comment"
            label="Notes"
            labelDirection="row"
            multiline
            inputProps={{ maxLength: 400 }}
            component={Input}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
