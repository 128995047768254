import { makeStyles } from '@mui/styles';
import theme from 'config/theme';
import { useState } from 'react';
import cn from 'classnames';
import { Sidebar, TenantSidebar } from '.';
import { Header } from './Header';

type LayoutProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() => {
  return {
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    content: {
      flex: 1,
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },

    contentShift: {
      marginLeft: '260px',
    },
  };
});

export const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  return (
    <>
      <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main
        className={cn(classes.contentContainer, {
          [classes.contentShift]: isMenuOpen,
        })}
      >
        <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <div className={classes.content}>{children}</div>
      </main>
    </>
  );
};

export const TenantLayout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  return (
    <>
      <TenantSidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main
        className={cn(classes.contentContainer, {
          [classes.contentShift]: isMenuOpen,
        })}
      >
        <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <div className={classes.content}>{children}</div>
      </main>
    </>
  );
};
