import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { paymentsApiClient } from 'api';
import { CheckDto } from 'api/payments-client';
import { Card, Title } from 'components';
import { useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { VscVerified, VscError } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { toTwoDecimals } from 'utils';

const fieldsWithDecimals = ['statementBalance', 'sumTransactions'];

export const ChecksPage = () => {
  const [expanded, setExpanded] = useState<Array<boolean>>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedCheck, setSelectedCheck] = useState<CheckDto>();
  const [selectedResultId, setSelectedResultId] = useState<string>();

  const [checks, setChecks] = useState<CheckDto[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await paymentsApiClient.api.checkControllerGetChecks();
      setChecks(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setExpanded(checks.map((e) => false));
  }, [checks]);

  const handleExpandClick = (idx: number) => {
    const newExpanded = Array.from(expanded);
    newExpanded[idx] = !expanded[idx];
    setExpanded(newExpanded);
  };
  const history = useHistory();
  const results = selectedCheck?.results.find((e) => e.id === selectedResultId);

  return (
    <Card>
      <Card.Body>
        <Title name="Checks"></Title>
        <List>
          {checks.map((check: CheckDto, idx) => {
            return (
              <ListItem key={check.id}>
                <Card>
                  <CardActions>
                    {check.results?.find((e2) => e2?.data?.length) ? (
                      <VscError size={20} color="red" />
                    ) : (
                      <VscVerified size={20} color="green" />
                    )}
                    {check.name}
                    <span
                      onClick={() => {
                        handleExpandClick(idx);
                      }}
                      aria-expanded={expanded[idx]}
                      aria-label="show more"
                      style={{ marginLeft: 'auto', cursor: 'pointer' }}
                    >
                      {expanded[idx] ? <MdExpandLess /> : <MdExpandMore />}
                    </span>
                  </CardActions>
                  <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                    <CardContent>
                      <List>
                        {check.results.map((e2) => (
                          <ListItem key={e2.id}>
                            {e2.data?.length ? (
                              <VscError size={20} color="red" />
                            ) : (
                              <VscVerified size={20} color="green" />
                            )}
                            <ListItemText primary={`${e2.meta.created}`} />
                            <Button
                              onClick={() => {
                                setSelectedCheck(check);
                                setSelectedResultId(e2.id);
                                setModalOpen(true);
                              }}
                            >
                              Details
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Collapse>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Card.Body>
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogContent>
          <List>
            <ListItem disablePadding>
              {Object.keys(results?.data?.[0] ?? {})?.map((key) => {
                return <ListItemText>{key}</ListItemText>;
              })}
            </ListItem>
            {results?.data?.map((row) => (
              // @ts-ignore
              <ListItem key={row.problem}>
                <ListItemButton
                  role={undefined}
                  onClick={() => {
                    switch (selectedCheck?.problemType) {
                      case 'BankStatementEntity': {
                        history.push({
                          pathname: 'bank-statements',
                          search: queryString.stringify({
                            // @ts-ignore
                            statementId: row.problem,
                          }),
                        });
                        break;
                      }
                      case 'PaymentEntity': {
                        history.push({
                          // @ts-ignore
                          pathname: `payments/${row.problem}`,
                        });
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                >
                  {Object.entries(row).map(([key, value]) => {
                    return (
                      <ListItemText
                        primary={
                          fieldsWithDecimals.includes(key)
                            ? toTwoDecimals(value)
                            : value
                        }
                      />
                    );
                  })}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
