import { useState } from 'react';
import { Field, Formik, Form, FieldArray } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaEdit, FaHistory, FaSave, FaWindowClose } from 'react-icons/fa';
import { BankAccountDTO, StatusChangeHistoryDto } from 'api/payments-client';
import { Input, Select } from 'components';
import { Currency, DebitDirectStatus, LSVStatus } from 'types/payment';
import { useRoles } from 'hooks';
import { moment } from 'types';
import { useAsync } from 'react-async-hook';
import { paymentsApiClient } from 'api';

const useStyles = makeStyles(() => ({
  block: {
    position: 'relative',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  iconsBlock: {
    position: 'absolute',
    top: -30,
    right: 0,
    '& > *': {
      padding: 0,
    },
    '& > *:not(:first-child)': {
      margin: '0 0 0 8px',
    },
  },
  historyIcon: {
    cursor: 'pointer',
    opacity: 1,

    '&:hover': {
      opacity: 0.5,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

type Props = {
  bankAccounts?: BankAccountDTO[];
  onSave: (arg0: BankAccountDTO[]) => void;
};

export const BankAccountsForm = ({ bankAccounts, onSave }: Props) => {
  const classes = useStyles();
  const { isSuperAdmin } = useRoles();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string | undefined>(undefined);

  const { result: changes = [] as Array<StatusChangeHistoryDto> } =
    useAsync(async () => {
      if (id) {
        const { data } =
          await paymentsApiClient.api.virtualAccountControllerGetLsvDdStatusHistory(
            id,
          );
        return data;
      }
    }, [id]);

  return (
    <Paper elevation={3}>
      <Typography variant="subtitle1" alignItems="center" display="flex">
        Bank details{' '}
      </Typography>

      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth={'md'}
      >
        <DialogTitle>History</DialogTitle>
        <DialogContent>
          <List>
            {changes?.map((e) => (
              <Grid container>
                <ListItem alignItems="flex-start">
                  <Grid item xs={4}>
                    <ListItemText
                      primary={moment(e?.dd?.date ?? e?.lsv?.date).format(
                        'DD.MM.YYYY HH:mm:ss',
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ListItemText primary={e?.dd?.status ?? e?.lsv?.status} />
                  </Grid>
                  <Grid item xs={4}>
                    <ListItemText primary={e?.dd?.name ?? e?.lsv?.name} />
                  </Grid>
                </ListItem>
              </Grid>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Formik
        enableReinitialize
        initialValues={{
          bankAccounts: Object.values(Currency).map(
            (currency) =>
              bankAccounts?.find((ba) => ba.currency === currency) ?? {
                currency,
                iban: '',
                id: '',
              },
          ),
        }}
        onSubmit={(values) => {
          onSave(values.bankAccounts);
          setIsDisabled(true);
        }}
      >
        {({ handleReset, values }) => (
          <Form>
            <FieldArray name="bankAccounts">
              {() => (
                <List>
                  {values.bankAccounts.map((ba, index) => (
                    <>
                      <ListItem
                        divider
                        disableGutters
                        key={`${index}_${ba.currency}`}
                      >
                        <Field
                          disabled={isDisabled}
                          id={`bankAccounts.${index}.iban`}
                          name={`bankAccounts.${index}.iban`}
                          label={
                            <>
                              {`${ba.currency} account`}
                              {ba.iban && (
                                <FaHistory
                                  size={20}
                                  onClick={() => {
                                    setIsOpen(true);
                                    setId(ba.id);
                                  }}
                                  className={classes.historyIcon}
                                />
                              )}
                            </>
                          }
                          labelDirection="row"
                          component={Input}
                          md={4}
                          className={classes.input}
                        />
                      </ListItem>
                      {ba.dd && (
                        <ListItem
                          divider
                          disableGutters
                          key={`${index}_${ba.currency}_DD`}
                        >
                          <Field
                            disabled={isDisabled}
                            id={`bankAccounts.${index}.dd.status`}
                            name={`bankAccounts.${index}.dd.status`}
                            label={`${ba.currency} Direct Debit`}
                            labelDirection="row"
                            component={Select}
                            md={4}
                            className={classes.input}
                            values={Object.values(DebitDirectStatus).map(
                              (e) => ({
                                value: e,
                                label: e,
                              }),
                            )}
                          />
                        </ListItem>
                      )}

                      {ba.lsv && (
                        <ListItem
                          divider
                          disableGutters
                          key={`${index}_${ba.currency}_LSV`}
                        >
                          <Field
                            disabled={isDisabled}
                            id={`bankAccounts.${index}.lsv.status`}
                            name={`bankAccounts.${index}.lsv.status`}
                            label={`${ba.currency} LSV`}
                            labelDirection="row"
                            component={Select}
                            md={4}
                            className={classes.input}
                            values={Object.values(LSVStatus).map((e) => ({
                              value: e,
                              label: e,
                            }))}
                          />
                        </ListItem>
                      )}
                    </>
                  ))}
                  {isSuperAdmin && (
                    <div className={classes.iconsBlock}>
                      {isDisabled && (
                        <IconButton type="button">
                          <FaEdit
                            size="24"
                            onClick={() => setIsDisabled(false)}
                          />
                        </IconButton>
                      )}
                      {!isDisabled && (
                        <IconButton type="submit" color="primary">
                          <FaSave size="24" />
                        </IconButton>
                      )}
                      {!isDisabled && (
                        <IconButton type="button">
                          <FaWindowClose
                            size="24"
                            onClick={() => {
                              handleReset();
                              setIsDisabled(true);
                            }}
                          />
                        </IconButton>
                      )}
                    </div>
                  )}
                </List>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
