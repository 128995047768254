import { paymentsApiClient } from 'api';
import {
  TransactionControllerGetTransactionsParams,
  TransactionEntity,
} from 'api/payments-client';
import { Card, Title } from 'components';
import { useQuery } from 'hooks/useQuery';
import { ParsedUrlQuery } from 'querystring';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router';
import { MatchState, TransactionStatus } from 'types/payment';
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from 'use-deep-compare-effect';

import { TransactionsTable } from './TransactionsTable';

export const TransactionsPage = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  const { pushQuery, query } = useQuery([
    'pageSize',
    'pageIndex',
    'transactionId',
    'type',
    'currency',
    'minAmount',
    'maxAmount',
    'matchState',
    'status',
    'referenceNumber',
    'clientName',
    'bankIban',
    'manualMatchTo',
  ]);
  const pageSize = !isNaN(Number(query.pageSize)) ? Number(query.pageSize) : 25;
  const pageIndex = !isNaN(Number(query.pageIndex))
    ? Number(query.pageIndex)
    : 0;

  const [rows, setRows] = useState<TransactionEntity[]>([]);
  const [rowsCount, setRowsCount] = useState<number>(0);
  const [options, setOptions] = useState<Record<string, string[]>>();
  const [filters, setFilters] = useState<Record<string, string[]>>();

  const fetchTransactions = useAsyncCallback(
    async (query: ParsedUrlQuery = {}) => {
      const { data } =
        await paymentsApiClient.api.transactionControllerGetTransactions({
          ...query,
          minAmount: query.minAmount ? +query.minAmount * 100 : undefined,
          maxAmount: query.maxAmount ? +query.maxAmount * 100 : undefined,
          tenantId,
        } as unknown as TransactionControllerGetTransactionsParams);
      setRows(data?.items ?? []);
      setRowsCount(data?.meta?.totalItems ?? 0);
      setOptions(data?.options);
      setFilters(data?.filters);
    },
  );

  const handleFiltersChange = async (data: {
    filters: Array<{ id: string; value: string | string[] }>;
    pageSize: number;
    pageIndex: number;
  }) => {
    const query = {
      ...data.filters
        .map((e) => ({
          [e.id]: Array.isArray(e.value) ? e.value.join(',') : e.value,
        }))
        .reduce((acc, val) => ({ ...acc, ...val }), {}),
      pageSize: data.pageSize.toString(),
      pageIndex: data.pageIndex.toString(),
    };
    pushQuery(query);
  };

  useDeepCompareEffect(() => {
    if (isEmpty(query)) {
      pushQuery({
        matchState: MatchState.Unmatched,
        status: TransactionStatus.Booked,
        pageSize: '25',
        pageIndex: '0',
      });
    } else {
      fetchTransactions.execute(query);
    }
  }, [query]);

  useDeepCompareEffectNoCheck(() => {
    if (filters) pushQuery({ ...query, ...filters });
  }, [filters]);

  return (
    <Card>
      <Card.Body>
        <Title name="Transactions"></Title>

        <TransactionsTable
          rows={rows}
          onFiltersChange={handleFiltersChange}
          count={rowsCount}
          initialFilters={Object.keys(query)
            .filter((key) => key !== 'pageSize' && key !== 'pageIndex')
            .map((key) => {
              const value = query[key];
              return {
                id: key,
                value: Array.isArray(value) ? value : value?.split(','),
              };
            })}
          pageSize={pageSize}
          pageIndex={pageIndex}
          options={options}
          quickFilters={{ id: '', values: [] }}
          match={async (e: TransactionEntity, search: string) => {
            await paymentsApiClient.api.transactionControllerMatch(
              e.transactionId,
              search,
            );
            await fetchTransactions.execute();
          }}
        />
      </Card.Body>
    </Card>
  );
};
