import { TransactionDto } from 'api/ledger-client';
import { Table } from 'components';
import { moment } from 'types';
import { CellProps } from 'react-table';
import { getAccount, toTwoDecimals } from 'utils';

type TransactionDtoType = { [key: string]: any } & TransactionDto;

interface FilterFragment {
  id: string;
  value: any;
}

interface TransactionTableProps {
  noFilters?: boolean;
  data: TransactionDto[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function TransactionsTable(props: TransactionTableProps) {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: undefined,
    },
    {
      Header: 'Account',
      accessor: 'account',
      ...(props.noFilters ? { Filter: undefined } : {}),
      Cell: function Cell({
        row: { original },
      }: CellProps<TransactionDtoType>) {
        return getAccount(original);
      },
    },
    {
      Header: 'Ledger Entry',
      accessor: 'ledgerEntry.id',
      Filter: undefined,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<TransactionDtoType>) {
        return toTwoDecimals(value);
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Filter: undefined,
    },
    {
      Header: 'Details',
      accessor: 'ledgerEntry.description',
      Filter: undefined,
    },
    {
      Header: 'Created date',
      accessor: 'ledgerEntry.createdDate',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<TransactionDtoType>) {
        return moment(value).format('DD.MM.YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <Table<TransactionDtoType>
      name="Transaction Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.data}
      columns={columns}
      count={props.count}
    />
  );
}
