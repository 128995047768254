/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProcessEntity {
  id: string;
  cronTime: string;

  /** @format date-time */
  createdDate: string;
  name: string;
  startDate?: string;
  endDate?: string;
  status: 'FINISHED' | 'ERROR' | 'RUNNING';
  errorMessage?: string;
  startedBy: string;
}

export interface EntityMetadataEntity {
  /** @format date-time */
  created: string;

  /** @format date-time */
  updated: string;
  version: number;
}

export interface AddressDTO {
  street: string;
  zipcode: string;
  city: string;
  country: string;
  streetNr?: string;
}

export interface ContactCreateDTO {
  firstName: string;
  lastName: string;
  address: AddressDTO;
  isCompany: boolean;
  email: string;
}

export interface DirectDebitDto {
  name: string;
  status: 'ACTIVE' | 'INACTIVE';
}

export interface LSVDto {
  name: string;
  status: 'ACTIVE' | 'INACTIVE';
}

export interface BankAccountCreateDTO {
  iban: string;
  currency: 'CHF' | 'EUR';
  dd?: DirectDebitDto;
  lsv?: LSVDto;
}

export interface VirtualAccountCreateDTO {
  name: string;
  tags?: object;
  tenant: string;
  organizationName: string;
  contact?: ContactCreateDTO;
  bankAccounts?: BankAccountCreateDTO[];

  /** @format date-time */
  createdAt?: string;
}

export interface PaymentDTO {
  id: string;
  status:
    | 'OPEN'
    | 'UPLOADED'
    | 'PENDING'
    | 'DECLINED'
    | 'DELETED'
    | 'PENDING_PARTIALLY'
    | 'BOOKED'
    | 'PARTIAL';
  declinedReason?: string;
  description: string;
  meta: EntityMetadataEntity;
  lastUpdateBy: string;

  /** @format date-time */
  lastUpdateDate: string;
  endToEndId?: string;
  virtualAccount: VirtualAccountCreateDTO;
  type: 'PAYIN' | 'PAYOUT';
  amount: number;
  currency: 'CHF' | 'EUR';
  method: 'BANK_TRANSFER' | 'CASH' | 'DEBIT_DIRECT' | 'LSV';
  reference?: string;

  /** @format date-time */
  transferDueDate?: string;
  bookingText?: string;
  comment?: string;
  extra?: object;
  virtualAccountId: string;
  contact?: ContactCreateDTO;
  bankAccount?: BankAccountCreateDTO;
  tenantId: string;
}

export interface PaymentCreateDTO {
  type: 'PAYIN' | 'PAYOUT';
  amount: number;
  currency: 'CHF' | 'EUR';
  method: 'BANK_TRANSFER' | 'CASH' | 'DEBIT_DIRECT' | 'LSV';
  reference?: string;

  /** @format date-time */
  transferDueDate?: string;
  bookingText?: string;
  comment?: string;
  extra?: object;
  virtualAccountId: string;
  contact?: ContactCreateDTO;
  bankAccount?: BankAccountCreateDTO;
  tenantId: string;
}

export interface QrCreditorDto {
  /** The IBAN. **21 characters.** */
  account: string;

  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface QrDebtorDto {
  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface QrDataDto {
  /** The currency to be used. **3 characters.** */
  currency: string;

  /** The amount. **Max. 12 digits.** */
  amount?: number;

  /**
   * A reference number. **Max 27 characters.**
   * > QR-IBAN: Maximum 27 characters. Must be filled if a QR-IBAN is used.
   * Creditor Reference (ISO 11649): Maximum 25 characters.
   */
  reference?: string;

  /**
   * A message. **Max. 140 characters.**
   * > message can be used to indicate the payment purpose or for additional textual information about payments with a structured reference.
   */
  message?: string;

  /**
   * Additional information. **Max 140 characters.**
   * > Bill information contain coded information for automated booking of the payment. The data is not forwarded with the payment.
   */
  additionalInformation?: string;

  /**
   * Alternative scheme. **Max. 100 characters.**
   * > Parameter character chain of the alternative scheme according to the syntax definition in the [“Alternative scheme” section](https://www.paymentstandards.ch/dam/downloads/ig-qr-bill-en.pdf)
   */
  av1?: string;

  /**
   * Alternative scheme. **Max. 100 characters.**
   * > Parameter character chain of the alternative scheme according to the syntax definition in the [“Alternative scheme” section](https://www.paymentstandards.ch/dam/downloads/ig-qr-bill-en.pdf)
   */
  av2?: string;

  /** Creditor related data. */
  creditor: QrCreditorDto;

  /** Debtor related data. */
  debtor?: QrDebtorDto;
}

export interface QrCodeDto {
  /** The SVG of the qr code (qr bill, or just qr code) */
  svg: string;

  /**
   * data used to generate the qr code, used in swissqrbill: https://github.com/schoero/SwissQRBill
   * const data = {
   *         currency: "CHF",
   *         amount: 1199.95,
   *         reference: "210000000003139471430009017",
   *         creditor: {
   *             name: "Robert Schneider AG",
   *             address: "Rue du Lac",
   *             buildingNumber: "1268",
   *             zip: 2501,
   *             city: "Biel",
   *             account: "CH4431999123000889012",
   *             country: "CH"
   *         },
   *         debtor: {
   *             name: "Pia-Maria Rutschmann-Schnyder",
   *             address: "Grosse Marktgasse",
   *             buildingNumber: "28",
   *             zip: 9400,
   *             city: "Rorschach",
   *         }
   *         };
   */
  data: QrDataDto;
}

export interface PdfDTO {
  name: string;
  size: number;
  mime: string;
  content: string;
}

export interface GetManyTenantEntityResponseDto {
  data: TenantEntity[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyVirtualAccountEntityResponseDto {
  data: VirtualAccountEntity[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface PaymentEntity {
  id: string;
  type: 'PAYIN' | 'PAYOUT';
  reference: string;
  amount: number;
  currency: 'CHF' | 'EUR';
  method: 'BANK_TRANSFER' | 'CASH' | 'DEBIT_DIRECT' | 'LSV';

  /** @format date-time */
  transferDueDate?: string;
  status:
    | 'OPEN'
    | 'UPLOADED'
    | 'PENDING'
    | 'DECLINED'
    | 'DELETED'
    | 'PENDING_PARTIALLY'
    | 'BOOKED'
    | 'PARTIAL';
  declinedReason?: string;
  comment?: string;
  bookingText: string;
  endToEndId: string;
  extra?: object;
  virtualAccount: VirtualAccountEntity;
  meta: EntityMetadataEntity;
  description: object;
}

export enum Iso2CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export interface AddressEntity {
  country: Iso2CountryCode;
  id: string;
  street: string;
  zipcode: string;
  city: string;
  streetNr?: string;
  contact: ContactEntity;
}

export interface ContactEntity {
  id: string;
  firstName: string;
  lastName?: string;
  address: AddressEntity;
  isCompany: boolean;
  email?: string;
  metadata?: object;
  virtualAccount: VirtualAccountEntity;
}

export interface DDStatusChangeEntity {
  id: string;
  name: string;
  status: 'ACTIVE' | 'INACTIVE';

  /** @format date-time */
  date: string;
  bankAccount: BankAccountEntity;
}

export interface LSVStatusChangeEntity {
  id: string;
  name: string;
  status: 'ACTIVE' | 'INACTIVE';

  /** @format date-time */
  date: string;
  bankAccount: BankAccountEntity;
}

export interface BankAccountEntity {
  iban: string;
  id: string;
  bankCode: string;
  name: string;
  bic: string;
  valid: boolean;
  dd?: DDStatusChangeEntity;
  lsv?: LSVStatusChangeEntity;
  currency: 'CHF' | 'EUR';
  virtualAccountId: string;
  virtualAccount: VirtualAccountEntity;
}

export interface VirtualAccountEntity {
  logger: object;
  id: string;
  name: string;
  tags?: object;
  tenant: TenantEntity;
  organizationName: string;
  payments?: PaymentEntity[];
  contact?: ContactEntity;
  bankAccounts?: BankAccountEntity[];
  meta: EntityMetadataEntity;
  lsvId: string;
  reference: string;
  qrId: string;
}

export interface TenantEntity {
  id: string;
  virtualAccounts: VirtualAccountEntity[];
  meta: EntityMetadataEntity;
}

export interface TenantCreateDTO {
  id: string;
}

export interface CreateManyTenantEntityDto {
  bulk: TenantCreateDTO[];
}

export interface BankAccountDTO {
  iban: string;
  id: string;
  currency: 'CHF' | 'EUR';
  dd?: DirectDebitDto;
  lsv?: LSVDto;
}

export interface BankAccountCreateDTOByVA {
  iban: string;
  currency: 'CHF' | 'EUR';
  dd?: DirectDebitDto;
  lsv?: LSVDto;
  tags?: object;
}

export interface StatusChangeHistoryDto {
  bankAccount: BankAccountEntity;
  lsv: LSVStatusChangeEntity;
  dd: DDStatusChangeEntity;
  id: string;
  bankAccountId: string;
  statusChangeId: string;
  meta: EntityMetadataEntity;
}

export interface CreateManyVirtualAccountEntityDto {
  bulk: VirtualAccountCreateDTO[];
}

export interface ContactUpdateDTO {
  firstName?: string;
  lastName?: string;
  address?: AddressDTO;
  isCompany?: boolean;
  email?: string;
}

export interface VirtualAccountUpdateDTO {
  organizationName?: string;
  contact?: ContactUpdateDTO;
  bankAccounts?: BankAccountCreateDTO[];
}

export interface TransactionCreateDTO {
  transactionId: string;
  referenceNumber: string;
  endToEndId: string;
  type: 'PAYIN' | 'PAYOUT';
  reference: string;
  amount: number;
  currency: 'CHF' | 'EUR';
  additional: string;
  bankName: string;
  bankIban: string;
  bookingDate: string;
  valueDate: string;
  clientName: string;
  debtorName: string;
  debtorIban: string;
  debtorAddress: object;
  debtorAgentName: string;
  debtorAgentIdentifier: string;
  debtorAgentAddress: object;
  creditorName: string;
  creditorIban: string;
  creditorAddress: object;
  creditorAgentName: string;
  creditorAgentIdentifier: string;
  creditorAgentAddress: object;
  matchState: 'NEW' | 'MATCHED' | 'UNMATCHED';
  statementId: string;
  paymentId: string;
  rawEntry: object;
  reversal: boolean;
}

export interface TransactionEntity {
  transactionId: string;
  referenceNumber: string;
  endToEndId: string;
  type: 'PAYIN' | 'PAYOUT';
  amount: number;
  currency: 'CHF' | 'EUR';
  additional: string;
  bankName: string;
  bankIban: string;
  bookingDate: string;
  valueDate: string;
  clientName: string;
  debtorName: string;
  debtorIban: string;
  debtorAddress: object;
  debtorAgentName: string;
  debtorAgentIdentifier: string;
  debtorAgentAddress: object;
  creditorName: string;
  creditorIban: string;
  creditorAddress: object;
  creditorAgentName: string;
  creditorAgentIdentifier: string;
  creditorAgentAddress: object;
  matchState: 'NEW' | 'MATCHED' | 'UNMATCHED';
  manualMatchBy: string;
  manualMatchDate: string;
  manualMatchTo: string;
  manualMatchingIndex: number;
  statementId?: string;
  paymentId?: string;
  rawEntry: object;
  status: 'BOOKED' | 'DELETED';
  reversal: boolean;
}

export interface DepositDataDto {
  iban: string;
  accountHolder: string;
  address: string;
}

export interface TransactionUpdateDto {
  transactionId: string;
  status: 'BOOKED' | 'DELETED';
}

export interface BankStatementEntity {
  statementId: string;
  openingBalance: number;
  closingBalance: number;
  sumOfTransactions: number;
  iban: string;
  fromDate: string;
  toDate: string;
  fileData: object;
  meta: EntityMetadataEntity;
}

export interface BusinessDaysBackDto {
  businessDaysBack?: number;
}

export interface CheckResultEntity {
  id: string;
  checkId: string;
  data: object[];
  meta: EntityMetadataEntity;
}

export interface CheckDto {
  id: string;
  name: string;
  configuration: string;
  isActive: boolean;
  description: string;
  problemType: 'BankStatementEntity' | 'PaymentEntity' | 'NONE';
  results: CheckResultEntity[];
}

export interface LSVFileEntity {
  id: string;
  status: 'CREATED' | 'RELEASED';
  dueDate: string;
  total: string;
  currency: 'CHF' | 'EUR';
  paymentIds?: string[];
  meta: EntityMetadataEntity;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

export interface PaymentControllerGetPaymentsParams {
  /** Comma separated enum values: 'PAYIN', 'PAYOUT' */
  type?: string;

  /** Comma separated enum values: 'BANK_TRANSFER', 'CASH', 'DEBIT_DIRECT', 'LSV' */
  method?: string;

  /** Comma separated enum values: 'OPEN', 'UPLOADED', 'PENDING', 'DECLINED', 'DELETED', 'PENDING_PARTIALLY', 'BOOKED', 'PARTIAL' */
  status?: string;

  /** Comma separated enum values: 'CHF', 'EUR' */
  currency?: string;
  id?: string;
  tenantId?: string;
  reference?: string;
  minAmount?: number;
  maxAmount?: number;

  /** @format date-time */
  minCreationDate?: string;

  /** @format date-time */
  maxCreationDate?: string;
  virtualAccountId?: string;
}

export interface PaymentControllerSearchParams {
  query: string;
  tenantId: string;
}

export interface PaymentControllerGetQrCodeParams {
  /** If present the amount will be part of the qr code, if not, the amount will have to be put by */
  amount?: number;

  /** CHF or EUR */
  currency?: string;

  /** If true, the full bill will be generated as SVG, otherwise, just the qr code */
  fullBill?: boolean;
  investorId: string;
}

export interface GetOneBaseTenantControllerTenantEntityParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
  slug: string;
}

export interface GetManyBaseTenantControllerTenantEntityParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
  s?: string;

  /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
  filter?: string[];

  /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
  or?: string[];

  /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
  sort?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
  limit?: number;

  /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
  offset?: number;

  /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
  page?: number;

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
}

export interface GetManyBaseVirtualAccountControllerVirtualAccountEntityParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
  s?: string;

  /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
  filter?: string[];

  /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
  or?: string[];

  /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
  sort?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
  limit?: number;

  /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
  offset?: number;

  /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
  page?: number;

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
}

export interface GetOneBaseVirtualAccountControllerVirtualAccountEntityParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
  id: string;
}

export interface TransactionControllerGetTransactionsParams {
  transactionId: string;
  type: string;
  currency: string;
  minAmount: string;
  maxAmount: string;
  matchState: string;
  referenceNumber: string;
  clientName: string;
  bankIban: string;
  manualMatchTo: string;
  status: string;
}

export interface TransactionControllerFindDepositDataParams {
  virtualAccountId: string;
}

export interface EbicsControllerExecuteEbicsOrderParams {
  order: string;
  bank: string;
}

export interface EbicsControllerGetBankStatementsParams {
  statementId?: string;
  iban?: string;
  openingBalance?: number;
  closingBalance?: number;

  /** @format date-time */
  fromDate?: string;

  /** @format date-time */
  toDate?: string;
}

export interface LsvFileControllerGetLsvFilesParams {
  pageSize: number;
  pageIndex: number;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Payments API
 * @version 1.0
 * @contact
 *
 * The Payments API.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags process
     * @name ProcessControllerGetProcesses
     * @summary Get all processes
     * @request GET:/api/process
     * @secure
     */
    processControllerGetProcesses: (params: RequestParams = {}) =>
      this.request<ProcessEntity[], any>({
        path: `/api/process`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessControllerStartProcess
     * @summary Starts a process
     * @request POST:/api/process/start/{name}
     * @secure
     */
    processControllerStartProcess: (name: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/process/start/${name}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerGetPayments
     * @summary Get all payments by tenant id
     * @request GET:/api/payments
     * @secure
     */
    paymentControllerGetPayments: (
      query: PaymentControllerGetPaymentsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: PaymentDTO[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerCreatePayment
     * @summary Adds a new payment.
     * @request POST:/api/payments
     * @secure
     */
    paymentControllerCreatePayment: (
      data: PaymentCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<PaymentDTO, any>({
        path: `/api/payments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerMarkAsPaid
     * @summary Marks a payment as paid
     * @request PATCH:/api/payments/{id}/paid
     * @secure
     */
    paymentControllerMarkAsPaid: (id: string, params: RequestParams = {}) =>
      this.request<PaymentDTO, any>({
        path: `/api/payments/${id}/paid`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns full information about the payments that matched by reference and by tenant name
     *
     * @tags payments
     * @name PaymentControllerSearch
     * @summary Search payments by matching reference and by tenant id
     * @request GET:/api/payments/search
     * @secure
     */
    paymentControllerSearch: (
      query: PaymentControllerSearchParams,
      params: RequestParams = {},
    ) =>
      this.request<PaymentDTO[], any>({
        path: `/api/payments/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerGetQrCode
     * @summary Gets a QR code in SVG format for deposits
     * @request GET:/api/payments/qr-code/{investorId}
     * @secure
     */
    paymentControllerGetQrCode: (
      { investorId, ...query }: PaymentControllerGetQrCodeParams,
      params: RequestParams = {},
    ) =>
      this.request<QrCodeDto, any>({
        path: `/api/payments/qr-code/${investorId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerFindOne
     * @summary Gets payment by id
     * @request GET:/api/payments/{id}
     * @secure
     */
    paymentControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<PaymentDTO, any>({
        path: `/api/payments/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerUpdatePayment
     * @summary Update payment
     * @request PUT:/api/payments/{id}
     * @secure
     */
    paymentControllerUpdatePayment: (
      id: string,
      data: PaymentDTO,
      params: RequestParams = {},
    ) =>
      this.request<PaymentDTO, any>({
        path: `/api/payments/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentControllerGetPdfInvoice
     * @summary Gets pdf invoice by payment id
     * @request GET:/api/payments/{id}/invoice
     * @secure
     */
    paymentControllerGetPdfInvoice: (id: string, params: RequestParams = {}) =>
      this.request<PdfDTO, any>({
        path: `/api/payments/${id}/invoice`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name GetOneBaseTenantControllerTenantEntity
     * @summary Retrieve a single TenantEntity
     * @request GET:/api/tenants/{slug}
     * @secure
     */
    getOneBaseTenantControllerTenantEntity: (
      { slug, ...query }: GetOneBaseTenantControllerTenantEntityParams,
      params: RequestParams = {},
    ) =>
      this.request<TenantEntity, any>({
        path: `/api/tenants/${slug}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name UpdateOneBaseTenantControllerTenantEntity
     * @summary Update a single TenantEntity
     * @request PATCH:/api/tenants/{slug}
     * @secure
     */
    updateOneBaseTenantControllerTenantEntity: (
      slug: string,
      data: TenantCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<TenantEntity, any>({
        path: `/api/tenants/${slug}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name ReplaceOneBaseTenantControllerTenantEntity
     * @summary Replace a single TenantEntity
     * @request PUT:/api/tenants/{slug}
     * @secure
     */
    replaceOneBaseTenantControllerTenantEntity: (
      slug: string,
      data: TenantCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<TenantEntity, any>({
        path: `/api/tenants/${slug}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name DeleteOneBaseTenantControllerTenantEntity
     * @summary Delete a single TenantEntity
     * @request DELETE:/api/tenants/{slug}
     * @secure
     */
    deleteOneBaseTenantControllerTenantEntity: (
      slug: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/tenants/${slug}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name GetManyBaseTenantControllerTenantEntity
     * @summary Retrieve multiple TenantEntities
     * @request GET:/api/tenants
     * @secure
     */
    getManyBaseTenantControllerTenantEntity: (
      query: GetManyBaseTenantControllerTenantEntityParams,
      params: RequestParams = {},
    ) =>
      this.request<GetManyTenantEntityResponseDto | TenantEntity[], any>({
        path: `/api/tenants`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name CreateOneBaseTenantControllerTenantEntity
     * @summary Create a single TenantEntity
     * @request POST:/api/tenants
     * @secure
     */
    createOneBaseTenantControllerTenantEntity: (
      data: TenantCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<TenantEntity, any>({
        path: `/api/tenants`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name CreateManyBaseTenantControllerTenantEntity
     * @summary Create multiple TenantEntities
     * @request POST:/api/tenants/bulk
     * @secure
     */
    createManyBaseTenantControllerTenantEntity: (
      data: CreateManyTenantEntityDto,
      params: RequestParams = {},
    ) =>
      this.request<TenantEntity[], any>({
        path: `/api/tenants/bulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name CreateOneBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Create a single VirtualAccountEntity
     * @request POST:/api/virtual-accounts
     * @secure
     */
    createOneBaseVirtualAccountControllerVirtualAccountEntity: (
      data: VirtualAccountCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<VirtualAccountEntity, any>({
        path: `/api/virtual-accounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name GetManyBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Retrieve multiple VirtualAccountEntities
     * @request GET:/api/virtual-accounts
     * @secure
     */
    getManyBaseVirtualAccountControllerVirtualAccountEntity: (
      query: GetManyBaseVirtualAccountControllerVirtualAccountEntityParams,
      params: RequestParams = {},
    ) =>
      this.request<
        GetManyVirtualAccountEntityResponseDto | VirtualAccountEntity[],
        any
      >({
        path: `/api/virtual-accounts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerCreateBankAccount
     * @summary Create bank account
     * @request POST:/api/virtual-accounts/{virtualAccountId}/bank-account
     * @secure
     */
    virtualAccountControllerCreateBankAccount: (
      virtualAccountId: string,
      data: BankAccountCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<BankAccountDTO, any>({
        path: `/api/virtual-accounts/${virtualAccountId}/bank-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name GetOneBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Retrieve a single VirtualAccountEntity
     * @request GET:/api/virtual-accounts/{id}
     * @secure
     */
    getOneBaseVirtualAccountControllerVirtualAccountEntity: (
      {
        id,
        ...query
      }: GetOneBaseVirtualAccountControllerVirtualAccountEntityParams,
      params: RequestParams = {},
    ) =>
      this.request<VirtualAccountEntity, any>({
        path: `/api/virtual-accounts/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name UpdateOneBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Update a single VirtualAccountEntity
     * @request PATCH:/api/virtual-accounts/{id}
     * @secure
     */
    updateOneBaseVirtualAccountControllerVirtualAccountEntity: (
      id: string,
      data: VirtualAccountUpdateDTO,
      params: RequestParams = {},
    ) =>
      this.request<VirtualAccountEntity, any>({
        path: `/api/virtual-accounts/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name ReplaceOneBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Replace a single VirtualAccountEntity
     * @request PUT:/api/virtual-accounts/{id}
     * @secure
     */
    replaceOneBaseVirtualAccountControllerVirtualAccountEntity: (
      id: string,
      data: VirtualAccountCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<VirtualAccountEntity, any>({
        path: `/api/virtual-accounts/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name DeleteOneBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Delete a single VirtualAccountEntity
     * @request DELETE:/api/virtual-accounts/{id}
     * @secure
     */
    deleteOneBaseVirtualAccountControllerVirtualAccountEntity: (
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/virtual-accounts/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description You can use 'I:VA' or 'DP:VA' as name and tags 'investorId' or 'dealProviderId' respectively
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerCreateBankAccountByAccountType
     * @summary Create bank account using investor ID
     * @request POST:/api/virtual-accounts/bank-account
     * @secure
     */
    virtualAccountControllerCreateBankAccountByAccountType: (
      name: 'I:VA' | 'DP:VA',
      data: BankAccountCreateDTOByVA,
      params: RequestParams = {},
    ) =>
      this.request<BankAccountDTO, any>({
        path: `/api/virtual-accounts/bank-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerUpdateBankAccountStatusChange
     * @summary Update bank account status change
     * @request PUT:/api/virtual-accounts/bank-account/{id}/status
     * @secure
     */
    virtualAccountControllerUpdateBankAccountStatusChange: (
      id: string,
      data: DirectDebitDto,
      params: RequestParams = {},
    ) =>
      this.request<BankAccountDTO, any>({
        path: `/api/virtual-accounts/bank-account/${id}/status`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerUpdateBankAccount
     * @summary Update bank account iban
     * @request PUT:/api/virtual-accounts/bank-account/{id}
     * @secure
     */
    virtualAccountControllerUpdateBankAccount: (
      id: string,
      data: BankAccountDTO,
      params: RequestParams = {},
    ) =>
      this.request<BankAccountDTO, any>({
        path: `/api/virtual-accounts/bank-account/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerRemoveBankAccount
     * @summary Remove bank account
     * @request DELETE:/api/virtual-accounts/bank-account/{id}
     * @secure
     */
    virtualAccountControllerRemoveBankAccount: (
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/virtual-accounts/bank-account/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name VirtualAccountControllerGetLsvDdStatusHistory
     * @summary Gets bank account lsv/DD status history
     * @request GET:/api/virtual-accounts/bank-account/{id}/history
     * @secure
     */
    virtualAccountControllerGetLsvDdStatusHistory: (
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<StatusChangeHistoryDto[], any>({
        path: `/api/virtual-accounts/bank-account/${id}/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-accounts
     * @name CreateManyBaseVirtualAccountControllerVirtualAccountEntity
     * @summary Create multiple VirtualAccountEntities
     * @request POST:/api/virtual-accounts/bulk
     * @secure
     */
    createManyBaseVirtualAccountControllerVirtualAccountEntity: (
      data: CreateManyVirtualAccountEntityDto,
      params: RequestParams = {},
    ) =>
      this.request<VirtualAccountEntity[], any>({
        path: `/api/virtual-accounts/bulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerCreateTransaction
     * @summary Adds a new transaction.
     * @request POST:/api/transactions
     * @secure
     */
    transactionControllerCreateTransaction: (
      data: TransactionCreateDTO,
      params: RequestParams = {},
    ) =>
      this.request<TransactionEntity, any>({
        path: `/api/transactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerGetTransactions
     * @summary Gets transactions
     * @request GET:/api/transactions
     * @secure
     */
    transactionControllerGetTransactions: (
      query: TransactionControllerGetTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: TransactionEntity[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerFindDepositData
     * @summary Get payment data
     * @request GET:/api/transactions/details
     * @secure
     */
    transactionControllerFindDepositData: (
      query: TransactionControllerFindDepositDataParams,
      params: RequestParams = {},
    ) =>
      this.request<DepositDataDto, any>({
        path: `/api/transactions/details`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerGetTransaction
     * @summary Get a transaction.
     * @request GET:/api/transactions/{id}
     * @secure
     */
    transactionControllerGetTransaction: (
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<TransactionEntity, any>({
        path: `/api/transactions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerMatch
     * @summary Match transaction with virtual account
     * @request POST:/api/transactions/{transactionId}/match/{virtualAccountId}
     * @secure
     */
    transactionControllerMatch: (
      transactionId: string,
      virtualAccountId: string,
      params: RequestParams = {},
    ) =>
      this.request<TransactionEntity, any>({
        path: `/api/transactions/${transactionId}/match/${virtualAccountId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerUnmatch
     * @summary Unmatch transaction
     * @request POST:/api/transactions/{transactionId}/umatch
     * @secure
     */
    transactionControllerUnmatch: (
      transactionId: string,
      params: RequestParams = {},
    ) =>
      this.request<TransactionEntity, any>({
        path: `/api/transactions/${transactionId}/umatch`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerRunMatchingProcess
     * @summary Run  Matching Process
     * @request POST:/api/transactions/match
     * @secure
     */
    transactionControllerRunMatchingProcess: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/transactions/match`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name TransactionControllerUpdate
     * @summary Update transaction
     * @request POST:/api/transactions/update
     * @secure
     */
    transactionControllerUpdate: (
      data: TransactionUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/transactions/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerGenerateLetter
     * @summary Generate letter to sign and send to the bank
     * @request POST:/api/ebics/letter/{bank}
     */
    ebicsControllerGenerateLetter: (bank: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ebics/letter/${bank}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerExecuteEbicsOrder
     * @summary Execute EBICS Order
     * @request POST:/api/ebics/order/{bank}
     */
    ebicsControllerExecuteEbicsOrder: (
      { bank, ...query }: EbicsControllerExecuteEbicsOrderParams,
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/ebics/order/${bank}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerGetBankStatements
     * @summary Returns statement
     * @request GET:/api/ebics/statement
     */
    ebicsControllerGetBankStatements: (
      query: EbicsControllerGetBankStatementsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: BankStatementEntity[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/ebics/statement`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerGetStatement
     * @summary Returns statement by id (MsgId)
     * @request GET:/api/ebics/statement/{id}
     */
    ebicsControllerGetStatement: (id: string, params: RequestParams = {}) =>
      this.request<BankStatementEntity, any>({
        path: `/api/ebics/statement/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerGetStatementData
     * @summary Returns statement file data by id (MsgId)
     * @request GET:/api/ebics/statement/{id}/data
     */
    ebicsControllerGetStatementData: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/ebics/statement/${id}/data`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerRunEbicsPfSaveProcess
     * @summary Get PF account statement for the past day. Save new transactions
     * @request POST:/api/ebics/save
     */
    ebicsControllerRunEbicsPfSaveProcess: (
      data: BusinessDaysBackDto,
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/ebics/save`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ebics
     * @name EbicsControllerRunEbicsHblSaveProcess
     * @summary Get HBL account statement for the past day. Save new transactions
     * @request POST:/api/ebics/savehbl
     */
    ebicsControllerRunEbicsHblSaveProcess: (
      data: BusinessDaysBackDto,
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/ebics/savehbl`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags checks
     * @name CheckControllerGetChecks
     * @summary Gets all checks
     * @request GET:/api/checks
     * @secure
     */
    checkControllerGetChecks: (params: RequestParams = {}) =>
      this.request<CheckDto[], any>({
        path: `/api/checks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lsv
     * @name LsvFileControllerGetLsvFiles
     * @summary Gets lsv files
     * @request GET:/api/lsv
     * @secure
     */
    lsvFileControllerGetLsvFiles: (
      query: LsvFileControllerGetLsvFilesParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: LSVFileEntity[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/lsv`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lsv
     * @name LsvFileControllerGenerateLsvFiles
     * @summary Generate LSV file(s)
     * @request POST:/api/lsv/generate
     * @secure
     */
    lsvFileControllerGenerateLsvFiles: (params: RequestParams = {}) =>
      this.request<LSVFileEntity[], any>({
        path: `/api/lsv/generate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lsv
     * @name LsvFileControllerGetLsvFile
     * @summary Download lsv file
     * @request GET:/api/lsv/{id}/file
     * @secure
     */
    lsvFileControllerGetLsvFile: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/lsv/${id}/file`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lsv
     * @name LsvFileControllerDeleteLsvFile
     * @summary Delete lsv file
     * @request DELETE:/api/lsv/{id}/file
     * @secure
     */
    lsvFileControllerDeleteLsvFile: (id: string, params: RequestParams = {}) =>
      this.request<LSVFileEntity, any>({
        path: `/api/lsv/${id}/file`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lsv
     * @name LsvFileControllerUpdateLsvFileStatus
     * @summary Update lsv file status
     * @request POST:/api/lsv/{id}/file/status/{status}
     * @secure
     */
    lsvFileControllerUpdateLsvFileStatus: (
      id: string,
      status: string,
      params: RequestParams = {},
    ) =>
      this.request<LSVFileEntity, any>({
        path: `/api/lsv/${id}/file/status/${status}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
