import { FocusEvent, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { GroupBase } from 'react-select';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import { TextFieldProps } from '@mui/material';
import { useAsyncDebounce } from 'react-table';
import { FieldProps, useField } from 'formik';

import { withFormLabel } from './withFormLabel';

type Option = { label: string; value: string };
type LoadOptions = Required<
  AsyncAdditionalProps<Option, GroupBase<Option>>
>['loadOptions'];

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    loadOptions: LoadOptions;
  };

export const LazySelect = withFormLabel(
  ({
    disabled,
    error,
    loadOptions,
    placeholder,
    ...props
  }: Props): JSX.Element => {
    const [field, , { setValue, setTouched }] = useField(props.field.name);

    const onBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (field.onBlur) field.onBlur(e);
        if (props.onBlur) props.onBlur(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onBlur, props.onBlur],
    );

    return (
      // @ts-ignore
      <AsyncSelect<Option>
        cacheOptions
        defaultOptions
        isDisabled={disabled}
        {...props}
        onChange={(newValue) => {
          setValue(newValue?.value ?? '', true);
        }}
        onBlur={(e) => {
          onBlur(e);
          setTouched(true, true);
        }}
        loadOptions={useAsyncDebounce(loadOptions, 1000)}
      />
    );
  },
);
