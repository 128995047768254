import { VirtualAccountEntity } from 'api/payments-client';
import useSWR from 'swr';

import { paymentsApiClient } from '../api';

export const useVirtualAccountSearch = (search = '', tenantId: string) => {
  const { data, error, isValidating } = useSWR(
    search === '' ? null : [search],
    async (search: string) => {
      const { data: deals } =
        await paymentsApiClient.api.getManyBaseVirtualAccountControllerVirtualAccountEntity(
          {
            s: `{"$and": [{"tenant.id": {"$eq": "${tenantId}"}}, {"$or": [{"id": {"$contL": "${search}"}}, {"organizationName": {"$contL": "${search}"}}, {"contact.firstName": {"$contL": "${search}"}}, {"contact.lastName": {"$contL": "${search}"}}]}]}`,
            limit: 10,
          },
        );
      return deals;
    },
  );

  return {
    data: (data ?? []) as Array<VirtualAccountEntity>,
    isLoading: isValidating,
    isError: error,
  };
};
