import { useMemo } from 'react';
import { TransactionEntity } from 'api/payments-client';
import { RangeColumnFilter, SelectColumnFilter, Table } from 'components';
import theme from 'config/theme';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import {
  Currency,
  MatchState,
  PaymentType as PaymentTypeEnum,
  TransactionStatus,
} from 'types/payment';
import { toTwoDecimals } from 'utils';

import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { moment } from 'types';

type TransactionType = { [key: string]: any } & TransactionEntity;

interface FilterFragment {
  id: string;
  value: any;
}

interface TransactionsTableProps {
  rows: TransactionEntity[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value?: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  options?: Record<string, string[]>;
  pageSize: number;
  pageIndex: number;
  count: number;
  match: (arg0: TransactionEntity, search: string) => Promise<void>;
}

const useStyles = makeStyles(
  (theme) => ({
    filterInput: {
      width: '180px',
    },
    searchField: {
      '& .MuiOutlinedInput-root': {
        width: '240px',
      },
    },
    searchIcon: {
      color: theme.palette.grey['600'],
    },
    filterSelect: {
      width: 'auto',
      minWidth: '50px',
    },
  }),
  { defaultTheme: theme },
);

export function TransactionsTable(props: TransactionsTableProps) {
  const classes = useStyles();
  const history = useHistory();
  const { options } = props;

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'transactionId',
        Cell: function Cell({ row: { original } }: CellProps<TransactionType>) {
          return (
            <Chip
              label={original.transactionId}
              onClick={() =>
                history.push(`transactions/${original.transactionId}`)
              }
            />
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              className={classes.filterSelect}
              filters={Object.values(PaymentTypeEnum)}
            />
          );
        },
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              className={classes.filterSelect}
              filters={Object.values(Currency)}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'minAmount',
      },
      {
        Header: '',
        accessor: 'maxAmount',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: function Cell({ value }: CellProps<TransactionType>) {
          return toTwoDecimals(value);
        },
        Filter: function Filter(otherProps: any) {
          return (
            <RangeColumnFilter
              {...otherProps}
              leftId="minAmount"
              rightId="maxAmount"
            />
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'bookingDate',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType>) {
          return moment(value).format('DD.MM.YYYY');
        },
      },
      {
        Header: 'Matching',
        accessor: 'matchState',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              className={classes.filterSelect}
              filters={Object.values(MatchState)}
            />
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              className={classes.filterSelect}
              filters={Object.values(TransactionStatus)}
            />
          );
        },
      },
      {
        Header: 'Reference',
        accessor: 'referenceNumber',
      },
      {
        Header: 'Name',
        accessor: 'clientName',
      },
      {
        Header: 'CG24 Account',
        accessor: 'bankIban',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              className={classes.filterSelect}
              filters={options?.bankIban ?? []}
            />
          );
        },
      },
      {
        Header: 'Matched to',
        accessor: 'manualMatchTo',
      },
      {
        Header: 'Reversal',
        accessor: 'reversal',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType>) {
          return value ? 'Yes' : 'No';
        },
      },
    ],
    [history, classes, options],
  );

  const hiddenColumns = ['minAmount', 'maxAmount'];

  return (
    <Table<TransactionType>
      name="Transactions Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.rows}
      columns={columns}
      count={props.count}
      hiddenColumns={hiddenColumns}
      quickFilters={props.quickFilters}
    />
  );
}
