import { Table } from 'components';
import { CellProps } from 'react-table';

import { Button } from '@mui/material';
import { useMemo } from 'react';
import { moment } from 'types';

type ProcessType = { [key: string]: any };

interface ProcessesTableProps {
  rows: ProcessType[];
  onRun: (name: string) => Promise<void>;
}

export function ProcessesTable(props: ProcessesTableProps) {
  const { onRun } = props;
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Filter: undefined,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<ProcessType>) {
          return moment(value).format('DD.MM.YYYY HH:mm:ss');
        },
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<ProcessType>) {
          return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '-';
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: undefined,
      },
      {
        Header: 'Started by',
        accessor: 'startedBy',
        Filter: undefined,
      },
      {
        Header: 'Error message',
        accessor: 'errorMessage',
        Filter: undefined,
      },
      {
        Header: '',
        accessor: 'run',
        Filter: undefined,
        Cell: function Cell({ row: { original } }: CellProps<ProcessType>) {
          return (
            <Button
              onClick={() => onRun(original.name as string)}
              disabled={original.status === 'RUNNING'}
            >
              Run
            </Button>
          );
        },
      },
    ],
    [onRun],
  );

  return (
    <Table<ProcessType>
      name="Processes Table"
      onFiltersChange={() => {}}
      pageSize={10}
      pageIndex={0}
      F
      data={props.rows}
      count={props.rows.length}
      columns={columns}
    />
  );
}
