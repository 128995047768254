import xml2js from 'xml2js';

export function downloadPDF(pdf: string, fileName: string): void {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


export function downloadXML(data: object, fileName: string): void {
  const builder = new xml2js.Builder();
  const xml = builder.buildObject(data);

  const linkSource = `data:text/plain;charset=utf-8,${encodeURIComponent(xml)}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = `${fileName}.xml`;
  downloadLink.click();
}


export function downloadPlain(data: string, fileName: string): void {
  const linkSource = `data:text/plain;charset=utf-8,${data}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
