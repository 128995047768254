import { Field } from 'formik';

import { Grid } from '@mui/material';
import { Input } from 'components';
import { notEmpty } from 'utils/validation';

export const AddTenantForm = () => {
  return (
    <>
      <Grid container>
        <Grid item md={6}>
          <Field
            required
            id="id"
            name="id"
            label="Name"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
      </Grid>
    </>
  );
};
