import React, { useMemo } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Input, Select, Title } from 'components';
import { Field, FieldArray, useFormikContext } from 'formik';
import { groupValidators, notEmpty, validEmail } from 'utils/validation';
import {
  BankAccountCreateDTO,
  VirtualAccountCreateDTO,
} from 'api/payments-client';
import { Currency } from 'types/payment';
import { useCountryList } from '../hooks/useCountryList';

const useStyles = makeStyles(() => ({
  trashIcon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },
  },
  addMore: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'underline',
    fontSize: '14px',
    margin: '20px 20px 0px 20px',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },
  },
}));

export const AddVirtualAccountForm = () => {
  const classes = useStyles();
  const { values } = useFormikContext<VirtualAccountCreateDTO>();
  const countryList = useCountryList(true);

  const bankAccountsLength = useMemo(
    () => (values.bankAccounts ? values.bankAccounts.length : 0),
    [values.bankAccounts],
  );

  const availableCurrencies = useMemo(
    () =>
      Object.values(Currency).map((curr) => ({
        value: curr,
        label: curr,
        disabled: !!values.bankAccounts?.find((ba) => ba.currency === curr),
      })),
    [values.bankAccounts],
  );
  return (
    <>
      <Title sub name="Account:" sx={{ marginBottom: 3 }} />
      <Grid container spacing={5} marginBottom={3}>
        <Grid item md={6}>
          <Field
            required
            id="name"
            name="name"
            label="Name (id)"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            id="tagKey"
            name="tagKey"
            label="Tag Key"
            labelDirection="row"
            component={Input}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            id="tagValue"
            name="tagValue"
            label="Tag Value"
            labelDirection="row"
            component={Input}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="organizationName"
            name="organizationName"
            label="Organization Name"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
      </Grid>
      <Title sub name="Bank accounts:" sx={{ marginBottom: 3 }} />
      <FieldArray name="bankAccounts">
        {({ push, remove }) => (
          <Grid container spacing={5} marginBottom={3}>
            {values?.bankAccounts?.map(
              (_: BankAccountCreateDTO, index: number) => (
                <React.Fragment key={index}>
                  <Grid item md={6}>
                    <Field
                      required
                      id={`bankAccounts.${index}.iban`}
                      name={`bankAccounts.${index}.iban`}
                      label="IBAN"
                      labelDirection="row"
                      component={Input}
                      validate={notEmpty}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <Field
                      required
                      id={`bankAccounts.${index}.currency`}
                      name={`bankAccounts.${index}.currency`}
                      label="Currency"
                      labelDirection="row"
                      component={Select}
                      values={availableCurrencies}
                      validate={notEmpty}
                    />
                  </Grid>
                  <Grid
                    item
                    md={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FaTrashAlt
                      size={20}
                      className={classes.trashIcon}
                      onClick={() => remove(index)}
                    />
                  </Grid>
                </React.Fragment>
              ),
            )}
            {bankAccountsLength < Object.values(Currency).length && (
              <div className={classes.addMore} onClick={() => push({})}>
                + Add bank account
              </div>
            )}
          </Grid>
        )}
      </FieldArray>
      <Title sub name="Contact:" sx={{ marginBottom: 3 }} />
      <Grid container spacing={5}>
        <Grid item md={6}>
          <Field
            required
            id="contact.firstName"
            name="contact.firstName"
            label="First Name"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.lastName"
            name="contact.lastName"
            label="Last Name"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.address.street"
            name="contact.address.street"
            label="Street"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.address.city"
            name="contact.address.city"
            label="City"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.address.zipcode"
            name="contact.address.zipcode"
            label="Zip Code"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.address.country"
            name="contact.address.country"
            label="Country"
            labelDirection="row"
            component={Select}
            values={countryList}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.address.streetNr"
            name="contact.address.streetNr"
            label="Street Nt"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="contact.isCompany"
            name="contact.isCompany"
            label="Company"
            labelDirection="row"
            component={Select}
            values={[
              { value: true, label: 'Contact is company' },
              { value: false, label: 'Contact is not company' },
            ]}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            type="email"
            id="contact.email"
            name="contact.email"
            label="Email"
            labelDirection="row"
            component={Input}
            validate={groupValidators(notEmpty, validEmail)}
          />
        </Grid>
      </Grid>
    </>
  );
};
