import React, { FC } from 'react';

import { Button, ButtonProps } from '@mui/material';

type Props = ButtonProps & {
  onValueChange: (value: boolean) => void;
  val?: boolean;
  defaultValue?: boolean;
  bypassColor?: string;
  activeColor?: string;
};

export const ToggleButton: FC<Props> = ({
  onValueChange,
  defaultValue = false,
  val,
  children,
  bypassColor = '#8a8a8a',
  activeColor = '#7db7e7',
  ...props
}) => {
  return (
    <Button
      style={{
        backgroundColor: val ? activeColor : bypassColor,
      }}
      {...props}
      onClick={() => {
        onValueChange(!val);
      }}
    >
      {children}
    </Button>
  );
};
