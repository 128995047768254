import { FieldProps } from 'formik';

import { MenuItem } from '@mui/material';
import MuiSelect, { SelectProps } from '@mui/material/Select';

import { withFormLabel } from './withFormLabel';

interface IItem<T = any> {
  label: string;
  value: T;
  disabled?: boolean;
}

type Props<T> = FieldProps &
  SelectProps & {
    initialValue?: T;
    values: IItem<T>[];
  };

export const Select = withFormLabel(
  <T,>({ initialValue, values, field, ...props }: Props<T>) => {
    const getKey = (value: T, label: string) => {
      if (typeof value === 'object') {
        return JSON.stringify(value);
      }

      if (['string', 'number'].includes(typeof value)) {
        return (value as unknown as string).toString();
      }

      return label;
    };

    return (
      <MuiSelect size="small" {...field} value={field.value ?? ''} {...props}>
        {values.map((v) => (
          <MenuItem key={getKey(v.value, v.label)} value={v.value as any} disabled={v.disabled}>
            {v.label}
          </MenuItem>
        ))}
      </MuiSelect>
    );
  },
);
