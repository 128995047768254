import { paymentsApiClient } from 'api';
import { LSVFileEntity } from 'api/payments-client';
import { Card, Title } from 'components';
import { useQuery } from 'hooks/useQuery';
import { ParsedUrlQuery } from 'querystring';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Button, Grid } from '@mui/material';

import { LSVFilesTable } from '../LSVFilesPage/LSVFilesTable';
import { CardBody } from 'components/card';
import { toast } from 'react-toastify';

export const LSVFilesPage = () => {
  const { pushQuery, query } = useQuery(['pageSize', 'pageIndex']);
  const pageSize = !isNaN(Number(query.pageSize)) ? Number(query.pageSize) : 10;
  const pageIndex = !isNaN(Number(query.pageIndex))
    ? Number(query.pageIndex)
    : 0;
  const [rows, setRows] = useState<LSVFileEntity[]>([]);
  const [rowsCount, setRowsCount] = useState<number>(0);

  const fetchLsvFiles = useAsyncCallback(async (query: ParsedUrlQuery = {}) => {
    const { data } = await paymentsApiClient.api.lsvFileControllerGetLsvFiles({
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    } as unknown as {
      pageSize: number;
      pageIndex: number;
    });
    setRows(data?.items ?? []);
    setRowsCount(data?.meta?.totalItems ?? 0);
  });

  useDeepCompareEffect(() => {
    if (isEmpty(query)) {
      pushQuery({
        pageSize: '10',
        pageIndex: '0',
      });
    } else {
      fetchLsvFiles.execute(query);
    }
  }, [query]);

  const generateLSVFiles = useAsyncCallback(async () => {
    const { data } =
      await paymentsApiClient.api.lsvFileControllerGenerateLsvFiles();
    toast.info(`Generated ${data.length ?? 0} LSV files`);
    await fetchLsvFiles.execute(query);
  });

  return (
    <Card>
      <CardBody>
        <Title name="LSV Files Table" />
        <Grid container justifyContent="center">
          <Button onClick={() => generateLSVFiles.execute()}>
            Generate LSV files
          </Button>
        </Grid>
        <LSVFilesTable
          rows={rows}
          onFiltersChange={() => {}}
          count={rowsCount}
          initialFilters={Object.keys(query)
            .filter((key) => key !== 'pageSize' && key !== 'pageIndex')
            .map((key) => {
              const value = query[key];
              return {
                id: key,
                value: Array.isArray(value) ? value : value?.split(','),
              };
            })}
          pageSize={pageSize}
          pageIndex={pageIndex}
          quickFilters={{ id: '', values: [] }}
          onDeleteRow={async (id) => {
            await paymentsApiClient.api.lsvFileControllerDeleteLsvFile(id);
            await fetchLsvFiles.execute(query);
          }}
        />
      </CardBody>
    </Card>
  );
};
