import { ReactNode, useMemo } from 'react';
import { countries, Country } from 'countries-list';
const priorityCountries = ['CH', 'DE', 'AT', 'LI']; // Short names for Switzerland, Germany, and Austria

function customSort(
  a: [string, Country],
  b: [string, Country],
) {
  const indexA = priorityCountries.indexOf(a[0]);
  const indexB = priorityCountries.indexOf(b[0]);

  // If both countries are in the priority list, sort them based on their order in the priority list
  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  // If only one of them is in the priority list, prioritize it
  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;

  // Use default alphabetical order for other countries
  return a[1].name.localeCompare(b[1].name);
}
export const useCountryList = (withPrioritySort = false) => {
  return useMemo(() => {
    const countryList: Array<{ label: string | ReactNode; value: string }> = [];

    let ogCountryList = Object.entries(countries);
    if (withPrioritySort) {
      ogCountryList.sort(customSort);
    }
    for (const [key, val] of ogCountryList) {
      countryList.push({ value: key, label: `${val.emoji} ${val.name}` });
    }

    return countryList;
  }, [withPrioritySort]);
};
