import { paymentsApiClient } from 'api';
import {
  PaymentCreateDTO,
  VirtualAccountUpdateDTO,
  VirtualAccountEntity,
  BankAccountDTO,
  BankAccountCreateDTO,
  DirectDebitDto,
  LSVDto,
} from 'api/payments-client';
import { Card, Title } from 'components';
import { useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { AddPaymentWidget } from 'widgets';
import { Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BalanceReportList } from './BalanceReportList/BalanceReportList';
import { TransactionsTable } from './TransactionsTable';
import { DetailsForm } from './DetailsForm/DetailsForm';
import { BankAccountsForm } from './BankAccountsForm/BankAccountsForm';
import { ContactForm } from './ContactForm/ContactForm';

const useStyles = makeStyles(() => ({
  block: {
    position: 'relative',
  },
}));

const getBook = (virtualAccount?: VirtualAccountEntity) => {
  if(!virtualAccount) return '';
  if (virtualAccount.name === 'I:VA') {
    return `investors/${Object.values(virtualAccount?.tags || [])[0]}`;
  }
  if (virtualAccount.name === 'DP:VA') {
    return `dealProviders/${Object.values(virtualAccount?.tags || [])[0]}/repayment`;
  }
  if (virtualAccount.name === 'DP:VA:Financing') {
    return `dealProviders/${Object.values(virtualAccount?.tags || [])[0]}/investment`;
  }
  if (virtualAccount.name === 'CG:VA:Error') {
    return 'loantrade';
  }
}

export const VirtualAccountDetailsPage = () => {
  const classes = useStyles();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const [virtualAccount, setVirtualAccount] =
    useState<VirtualAccountEntity | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);



  const liabilitiesAccount = id;
  const {
    loading: vaLoading,
    error: vaError,
    execute: reloadVirtualAccount,
  } = useAsync(async () => {
    const { data } =
      await paymentsApiClient.api.getOneBaseVirtualAccountControllerVirtualAccountEntity(
        { id },
      );

    setVirtualAccount(data);
    return data;
  }, [liabilitiesAccount]);

  const {
    result: balance,
    loading,
    error,
  } = useAsync(async () => {
    if (virtualAccount) {
      const { data: chf } =
        await window.ledgerApiClient.api.queryControllerGetBalance({
          tenantId,
          accounts: ['VirtualAccount:Assets:Current'],
          book: getBook(virtualAccount),
          groupBy: [],
          tags: virtualAccount?.tags as Record<string, string>,
          currency: 'CHF',
        });
      const { data: eur } =
        await window.ledgerApiClient.api.queryControllerGetBalance({
          tenantId,
          accounts: ['VirtualAccount:Assets:Current'],
          book: getBook(virtualAccount),
          groupBy: [],
          tags: virtualAccount?.tags as Record<string, string>,
          currency: 'EUR',
        });

      return { CHF: chf[0], EUR: eur[0] };
    }
  }, [virtualAccount]);

  const {
    result: { items: transactions = [], meta: { totalItems = 0 } = {} } = {},
  } = useAsync(async () => {
    const { data } =
      await window.ledgerApiClient.api.queryControllerGetTransactions({
        tenantId,
        accounts: ['VirtualAccount:Assets:Current'],
        book: getBook(virtualAccount || undefined),
        tags: virtualAccount?.tags as Record<string, string>,
        take: pageSize,
        skip: pageSize * pageIndex,
      });
    return data;
  }, [virtualAccount?.name, pageSize, pageIndex]);

  const handleFiltersChange = (data: {
    pageSize: number;
    pageIndex: number;
  }) => {
    if (+data.pageSize !== pageSize) setPageSize(+data.pageSize);
    if (+data.pageIndex !== pageIndex) setPageIndex(+data.pageIndex);
  };

  const createPayment = useAsyncCallback(async (payment: PaymentCreateDTO) => {
    await paymentsApiClient.api.paymentControllerCreatePayment({
      ...payment,
      transferDueDate: payment.transferDueDate,
    });
  });

  const updateVirtualAccount = useAsyncCallback(
    async (updateVirtualAccount: VirtualAccountUpdateDTO) => {
      await paymentsApiClient.api.updateOneBaseVirtualAccountControllerVirtualAccountEntity(
        id,
        {
          ...virtualAccount,
          ...updateVirtualAccount,
        },
      );
      await reloadVirtualAccount();
    },
  );

  const createBankAccount = useAsyncCallback(
    async (createBankAccount: BankAccountCreateDTO) => {
      if (!virtualAccount) return;
      await paymentsApiClient.api.virtualAccountControllerCreateBankAccount(
        virtualAccount.id,
        createBankAccount,
      );
    },
  );

  const updateBankAccount = useAsyncCallback(
    async (updateBankAccount: BankAccountDTO) => {
      await paymentsApiClient.api.virtualAccountControllerUpdateBankAccount(
        updateBankAccount.id,
        updateBankAccount,
      );
    },
  );

  const updateBankAccountDDStatus = useAsyncCallback(
    async (bankAccountId: string, dto: DirectDebitDto) => {
      await paymentsApiClient.api.virtualAccountControllerUpdateBankAccountStatusChange(
        bankAccountId,
        dto,
      );
    },
  );

  const removeBankAccount = useAsyncCallback(async (id: string) => {
    await paymentsApiClient.api.virtualAccountControllerRemoveBankAccount(id);
  });

  const handleBankAccounts = useAsyncCallback(
    async (bankAccounts?: BankAccountDTO[]) => {
      const handlers: Promise<void>[] = [];
      virtualAccount?.bankAccounts?.forEach((oldBA) => {
        const newBankAccount = bankAccounts?.find(
          (newBA) => newBA.id === oldBA.id,
        );
        if (!newBankAccount || !newBankAccount.iban) {
          handlers.push(removeBankAccount.execute(oldBA.id));
        } else if (newBankAccount.iban !== oldBA.iban) {
          handlers.push(updateBankAccount.execute(newBankAccount));
        } else if (
          newBankAccount.dd &&
          newBankAccount.dd?.status !== (oldBA.dd?.status ?? oldBA.lsv?.status)
        ) {
          handlers.push(
            updateBankAccountDDStatus.execute(oldBA.id, {
              status: newBankAccount.dd!.status,
            } as DirectDebitDto),
          );
        } else if (
          newBankAccount.lsv &&
          newBankAccount.lsv?.status !== (oldBA.dd?.status ?? oldBA.lsv?.status)
        ) {
          handlers.push(
            updateBankAccountDDStatus.execute(oldBA.id, {
              status: newBankAccount.lsv!.status,
            } as LSVDto),
          );
        }
      });

      bankAccounts?.forEach((newBA) => {
        if (!newBA.id && newBA.iban) {
          handlers.push(
            createBankAccount.execute(newBA as BankAccountCreateDTO),
          );
        }
      });

      if (handlers.length) {
        await Promise.all(handlers);
        await reloadVirtualAccount();
      }
    },
  );

  if ((loading || vaLoading) && !virtualAccount) {
    return <CircularProgress size={40} />;
  }
  if (error || vaError || !virtualAccount) {
    return <div>Account not found</div>;
  }

  return (
    <Card>
      <Card.Body
        loadingContent={
          loading ||
          vaLoading ||
          handleBankAccounts.loading ||
          updateVirtualAccount.loading ||
          createBankAccount.loading ||
          updateBankAccount.loading ||
          updateBankAccountDDStatus.loading ||
          removeBankAccount.loading
        }
      >
        <Title name={`Account ${liabilitiesAccount}`}>
          <AddPaymentWidget
            onSubmit={createPayment.execute}
            tenantId={tenantId}
            initialValues={{ virtualAccountId: id }}
          />
        </Title>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <DetailsForm
                      virtualAccount={virtualAccount}
                      onSave={updateVirtualAccount.execute}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BankAccountsForm
                      bankAccounts={virtualAccount?.bankAccounts}
                      onSave={handleBankAccounts.execute}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <ContactForm
                  virtualAccount={virtualAccount}
                  onSave={updateVirtualAccount.execute}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Typography variant="subtitle1">Balance report</Typography>
              <Grid container>
                <Grid item xs={12} className={classes.block}>
                  <BalanceReportList entry={balance} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Typography variant="subtitle1">
                Account's transactions
                <Chip
                  label="Go to Ledger"
                  onClick={() =>
                    window.open(
                      `${
                        window.appConfig.ledgerBaseUrl
                      }/tenants/${tenantId}/accounts?accounts[]=${
                        'VirtualAccount:Assets:Current'
                      }&book=${getBook(virtualAccount)}%25&groupBy[]=${
                        Object.keys(virtualAccount?.tags ?? {})?.[0]
                      }`,
                      '_blank',
                    )
                  }
                />
              </Typography>
              <TransactionsTable
                noFilters
                data={transactions}
                onFiltersChange={handleFiltersChange}
                count={totalItems}
                initialFilters={[]}
                pageSize={pageSize}
                pageIndex={pageIndex}
                quickFilters={{ id: '', values: [] }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
