import { paymentsApiClient } from 'api';
import {
  PaymentControllerGetPaymentsParams,
  PaymentCreateDTO,
  PaymentDTO,
} from 'api/payments-client';
import { Card, Title } from 'components';
import theme from 'config/theme';
import { usePaymentsSearch } from 'hooks';
import { useQuery } from 'hooks/useQuery';
import { ParsedUrlQuery } from 'querystring';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useDebounce } from 'use-debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { AddPaymentWidget } from 'widgets';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaymentsTable } from './PaymentsTable';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) => ({
    searchField: {
      '& .MuiOutlinedInput-root': {
        width: '300px',
      },
    },
    searchIcon: {
      color: theme.palette.grey['600'],
    },
  }),
  { defaultTheme: theme },
);

export const PaymentsPage = () => {
  const classes = useStyles();
  const { tenantId } = useParams<{ tenantId: string }>();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const [debouncedSearch, controlFns] = useDebounce(search, 500);
  const loading = controlFns.isPending();
  const [open, setOpen] = useState(false);

  const { data: options } = usePaymentsSearch(debouncedSearch, tenantId);

  const { pushQuery, query } = useQuery([
    'pageSize',
    'pageIndex',
    'type',
    'method',
    'reference',
    'status',
    'currency',
    'minAmount',
    'maxAmount',
    'minCreationDate',
    'maxCreationDate',
    'virtualAccountId',
    'id',
  ]);
  const pageSize = !isNaN(Number(query.pageSize)) ? Number(query.pageSize) : 10;
  const pageIndex = !isNaN(Number(query.pageIndex))
    ? Number(query.pageIndex)
    : 0;
  const [rows, setRows] = useState<PaymentDTO[]>([]);
  const [rowsCount, setRowsCount] = useState<number>(0);

  const fetchPayments = useAsyncCallback(async (query: ParsedUrlQuery = {}) => {
    const { data } = await paymentsApiClient.api.paymentControllerGetPayments({
      ...query,
      minAmount: query.minAmount ? +query.minAmount * 100 : undefined,
      maxAmount: query.maxAmount ? +query.maxAmount * 100 : undefined,
      tenantId,
    } as unknown as PaymentControllerGetPaymentsParams);
    setRows(data?.items ?? []);
    setRowsCount(data?.meta?.totalItems ?? 0);
  });

  const handleFiltersChange = async (data: {
    filters: Array<{
      id: string;
      value: string | string[];
    }>;
    pageSize: number;
    pageIndex: number;
  }) => {
    pushQuery({
      ...data.filters
        .map((e) => ({
          [e.id]: Array.isArray(e.value) ? e.value.join(',') : e.value,
        }))
        .reduce((acc, val) => ({ ...acc, ...val }), {}),
      pageSize: data.pageSize.toString(),
      pageIndex: data.pageIndex.toString(),
    });
  };

  const createPayment = useAsyncCallback(async (payment: PaymentCreateDTO) => {
    await paymentsApiClient.api.paymentControllerCreatePayment({
      ...payment,
    });
    await fetchPayments.execute();
  });

  const markAsPaid = useAsyncCallback(async (payment: PaymentDTO) => {
    await paymentsApiClient.api.paymentControllerMarkAsPaid(payment.id);
    await fetchPayments.execute();
  });

  useDeepCompareEffect(() => {
    if (isEmpty(query)) {
      pushQuery({
        pageSize: '10',
        pageIndex: '0',
      });
    } else {
      fetchPayments.execute(query);
    }
  }, [query]);

  return (
    <Card>
      <Card.Body>
        <Title name="Payments">
          <AddPaymentWidget
            onSubmit={createPayment.execute}
            tenantId={tenantId}
          />
        </Title>
        <PaymentsTable
          rows={rows}
          onFiltersChange={handleFiltersChange}
          count={rowsCount}
          initialFilters={Object.keys(query)
            .filter((key) => key !== 'pageSize' && key !== 'pageIndex')
            .map((key) => {
              const value = query[key];
              return {
                id: key,
                value: Array.isArray(value) ? value : value?.split(','),
              };
            })}
          pageSize={pageSize}
          pageIndex={pageIndex}
          quickFilters={{ id: '', values: [] }}
          markAsPaid={markAsPaid.execute}
          autocomplete={
            <Autocomplete
              id="quick-search"
              inputValue={search}
              onInputChange={(e, value) => setSearch(value)}
              open={open}
              onChange={(_, option) => {
                if (option?.id) history.push(`payments/${option.id}`);
              }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={options}
              loading={loading}
              filterOptions={(options) => options} // Do NOT filter options
              getOptionLabel={(option) => {
                return `${option.reference} | ${option.bookingText}`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Quick Search"
                  variant="outlined"
                  className={classes.searchField}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <FaSearch className={classes.searchIcon} />
                        )}
                      </>
                    ),
                  }}
                />
              )}
            />
          }
        />
      </Card.Body>
    </Card>
  );
};
