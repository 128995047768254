export enum PaymentType {
  PAYIN = 'PAYIN',
  PAYOUT = 'PAYOUT',
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  DEBIT_DIRECT = 'DEBIT_DIRECT',
  CASH = 'CASH',
  LSV = 'LSV'
}

export enum PaymentStatus {
  OPEN = 'OPEN',
  UPLOADED = 'UPLOADED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
  PENDING_PARTIALLY = 'PENDING_PARTIALLY',
  BOOKED = 'BOOKED',
  PARTIAL = 'PARTIAL',
}

export enum Currency {
  CHF = 'CHF',
  EUR = 'EUR',
}

export enum MatchState {
  New = 'NEW',
  Matched = 'MATCHED',
  Unmatched = 'UNMATCHED',
}

export enum TransactionStatus {
  Booked = 'BOOKED',
  Deleted = 'DELETED',
}

export enum DebitDirectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum LSVStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum LSVFileEntityStatus {
  Created = 'CREATED',
  Released = 'RELEASED',
}