import { TenantCreateDTO, TenantEntity } from 'api/payments-client';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { Link } from 'react-router-dom';

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { paymentsApiClient } from '../api';
import { Layout, Title } from '../components';
import { AddTenantWidget } from '../widgets';

const useStyles = makeStyles(() => ({
  table: {
    width: 'auto',
  },
}));

export const TenantsPage = () => {
  const classes = useStyles();

  const { result, execute: fetchTenants } = useAsync(
    async () =>
      await paymentsApiClient.api.getManyBaseTenantControllerTenantEntity({}),
    [],
  );
  const rows = (result?.data as TenantEntity[]) ?? [];

  const createTenant = useAsyncCallback(async (tenant: TenantCreateDTO) => {
    await paymentsApiClient.api.createOneBaseTenantControllerTenantEntity(
      tenant,
    );
    await fetchTenants();
  });

  return (
    <Layout>
      <Container>
        <Title name="Tenants">
          <AddTenantWidget onSubmit={createTenant.execute} />
        </Title>
        <TableContainer component={Paper} className={classes.table}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`/tenants/${row.id}`}>{row.id}</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
};
