import { useAuth0 } from '@auth0/auth0-react';
import { Role } from '../types';

export const useRoles = () => {
  const { user } = useAuth0();
  const roles: Array<Role> =
    user?.[`https://creditgate24.com/roles`] ?? [];

  return {
    isViewOnly: roles.includes(Role.PaymentsViewOnly),
    isSuperAdmin: roles.includes(Role.PaymentsSuperAdmin),
  };
};
