import { FieldProps } from 'formik';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    min?: number;
    max?: number;
    step?: number;
  };

export const Input = withFormLabel(
  ({
    disabled,
    multiline,
    field,
    min,
    max,
    step,
    error = false,
    type,
    ...props
  }: Props): JSX.Element => (
    <TextField
      multiline={multiline}
      disabled={disabled}
      error={error}
      size="small"
      variant="outlined"
      inputProps={{
        min,
        max,
        step,
      }}
      InputProps={{...props.InputProps}}
      type={type}
      {...field}
      {...props}
    />
  ),
);
