import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { FieldProps } from 'formik';
import { Autocomplete, CircularProgress } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FaSearch } from 'react-icons/fa';

import { withFormLabel } from './withFormLabel';
import theme from 'config/theme';

type Props<T> = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    options: T[];
    getOptionLabel: (option: T) => string;
    optionKey: string;
    useSearch: (search: string, ...params: any) => { data: T[], isLoading: boolean };
    searchParams: any[];
  };

const useStyles = makeStyles(
  (theme) => ({
    searchIcon: {
      color: theme.palette.grey['600'],
    },
  }),
  { defaultTheme: theme },
);


export const InputSearch = withFormLabel(
  <T,>({
    disabled,
    multiline,
    field,
    form,
    error = false,
    type,
    useSearch,
    searchParams,
    getOptionLabel,
    optionKey,
    ...props
  }: Props<T>): JSX.Element => {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [debouncedSearch, controlFns] = useDebounce(search, 500);
    const loading = controlFns.isPending();
    const [open, setOpen] = useState(false);

    const { data: options, isLoading: searchIsLoading } = useSearch(
      debouncedSearch,
      ...searchParams
    );
    return (
      <Autocomplete
        disableClearable
        id={`${field.name}-search`}
        inputValue={search}
        onInputChange={(e, value) => setSearch(value)}
        open={open}
        onChange={(_, option: any) => {
          const value = option?.[optionKey];
          if (value) form.setFieldValue(field.name, value);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        loading={loading}
        filterOptions={(options) => options} // Do NOT filter options
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            multiline={multiline}
            disabled={disabled}
            error={error}
            size="small"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading || searchIsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <FaSearch className={classes.searchIcon} />
                  )}
                </>
              ),
            }}
            type="text"
            {...field}
            onChange={(e) => {
              if (!e.target.value)
                form.setFieldValue(field.name, '');
            }}
            {...props}
          />
        )}
      />
    )
  },
);
