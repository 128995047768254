import { LSVFileEntity } from 'api/payments-client';
import { DownloadButton, Table } from 'components';
import { CellProps } from 'react-table';
import { useHistory } from 'react-router-dom';

import { Chip, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useMemo, useState } from 'react';
import { moment } from 'types';
import { paymentsApiClient } from 'api';
import { downloadPlain, toTwoDecimals } from 'utils';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';
import { LSVFileEntityStatus } from 'types/payment';
import queryString from 'query-string';
import { MdPayments } from 'react-icons/md';

type LSVFileType = { [key: string]: any } & LSVFileEntity;

interface FilterFragment {
  id: string;
  value: any;
}

interface LSVFilesTableProps {
  rows: LSVFileType[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value?: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize: number;
  pageIndex: number;
  count: number;
  onDeleteRow: (id: string) => Promise<void>;
}

export function LSVFilesTable(props: LSVFilesTableProps) {
  const lsvFileStatusOptions = useMemo(
    () =>
      Object.values(LSVFileEntityStatus).map((e) => ({
        value: e,
        label: e,
      })),
    [],
  );

  const history = useHistory();

  const { onDeleteRow } = props;

  const columns = useMemo(
    () => [
      {
        Header: 'Creation date',
        accessor: 'meta.created',
        Cell: function Cell({ value }: CellProps<LSVFileType>) {
          return moment(value).format('DD.MM.YYYY HH:mm:ss');
        },
        Filter: undefined,
      },
      {
        Header: 'Due date',
        accessor: 'dueDate',
        Cell: function Cell({ value }: CellProps<LSVFileType>) {
          return moment(value).format('DD.MM.YYYY');
        },
        Filter: undefined,
      },
      {
        Header: 'Total amount',
        accessor: 'total',
        Cell: function Cell({
          value,
          row: { original },
        }: CellProps<LSVFileType>) {
          return `${original.currency} ${toTwoDecimals(value)}`;
        },
        Filter: undefined,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: undefined,
        Cell: function Cell({
          value,
          row: { original },
        }: CellProps<LSVFileType>) {
          const [status, setStatus] = useState(value ?? '');
          return (
            <Select
              size="small"
              value={status}
              onChange={async (e: SelectChangeEvent) => {
                await paymentsApiClient.api.lsvFileControllerUpdateLsvFileStatus(
                  original.id,
                  e.target.value,
                );
                setStatus(e.target.value);
              }}
            >
              {lsvFileStatusOptions.map((v) => (
                <MenuItem key={v.value} value={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        Header: 'Payments',
        accessor: '',
        Cell: function Cell({ row: { original } }: CellProps<LSVFileType>) {
          return (
            <Chip
              label={<MdPayments />}
              onClick={() =>
                history.push({
                  pathname: 'payments',
                  search: queryString.stringify({
                    id: original.paymentIds,
                  }),
                })
              }
            />
          );
        },
        Filter: undefined,
      },
      {
        Header: 'Download',
        accessor: '',
        Cell: function Cell({ row: { original } }: CellProps<LSVFileType>) {
          return (
            <DownloadButton
              onClick={async () => {
                const { data } =
                  await paymentsApiClient.api.lsvFileControllerGetLsvFile(
                    original.id,
                  );
                if (data) {
                  downloadPlain(data, `LSV_${original.id}_${original.dueDate}`);
                }
              }}
            >
              <FaDownload />
            </DownloadButton>
          );
        },
        Filter: undefined,
      },
      {
        Header: 'Delete',
        accessor: '',
        Cell: function Cell({ row: { original } }: CellProps<LSVFileType>) {
          return (
            <DownloadButton onClick={() => onDeleteRow(original.id)}>
              <FaTrashAlt />
            </DownloadButton>
          );
        },
        Filter: undefined,
      },
    ],
    [history, lsvFileStatusOptions, onDeleteRow],
  );

  return (
    <Table<LSVFileType>
      name="LSV Files Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.rows}
      columns={columns}
      count={props.count}
    />
  );
}
