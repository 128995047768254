import clsx from 'clsx';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';

interface IBreadcrumb {
  title: string;
  href?: string;
  active?: boolean;
}

interface Props {
  name: string;
  sub?: boolean;
  breadcrumbs?: IBreadcrumb[];
  children?: React.ReactElement;
  sx?: SxProps<Theme>;
}

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    '&.lg': {
      minHeight: '48px',
      paddingBottom: '0.5em',
    },
  },
}));

export const Title = ({
  sub = false,
  name,
  children,
  breadcrumbs,
  sx,
}: Props) => {
  const classes = useStyles();

  const breadcrumbsComponent = useMemo(() => {
    if (!breadcrumbs) return null;

    return (
      <Box marginLeft="1em">
        <Breadcrumbs>
          {breadcrumbs.map((bc, i) => {
            if (bc.href) {
              return (
                <Link
                  key={bc.href + i}
                  component={RouterLink}
                  to={bc.href}
                  underline="hover"
                  color={bc.active ? 'text.primary' : 'inherit'}
                >
                  {bc.title}
                </Link>
              );
            }

            return (
              <Typography
                key={bc.title + i}
                color={bc.active ? 'text.primary' : 'inherit'}
              >
                {bc.title}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
    );
  }, [breadcrumbs]);

  return (
    <Box marginBottom={sub ? '8px' : '16px'} sx={sx}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={clsx(classes.container, { lg: !sub })}
      >
        <Grid container direction="row" alignItems="center" width="auto">
          <Typography
            gutterBottom
            color="textPrimary"
            variant={sub ? 'subtitle1' : 'h6'}
            marginBottom={0}
          >
            {name}
          </Typography>
          {breadcrumbsComponent}
        </Grid>
        {children}
      </Grid>
      <Divider />
    </Box>
  );
};
