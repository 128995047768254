import React from 'react';
import { Chip as MaterialChip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldProps } from 'formik';
import { withFormLabel } from './withFormLabel';

type Props = FieldProps & { onClick: (arg0: string) => void };

const useStyles = makeStyles({
  chipWrapper: {
    width: '200px',
    padding: '0 14px 8px 6px',
  },
});

export const Chip = withFormLabel(({ field, onClick }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.chipWrapper}>
      <MaterialChip label={field.value} onClick={() => onClick(field.value)} />
    </div>
  );
});
