import { createTheme } from '@mui/material';

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: '#1a4063',
    },
    secondary: {
      main: '#c4c4c4',
    },
    text: {
      primary: '#444444',
      secondary: '#6b6b6b',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: '16px',
          fontWeight: 600,
        },
        body2: {
          color: 'grey',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        text: {
          whiteSpace: 'nowrap',
        },
        sizeSmall: {
          padding: '5px 15px',
          fontSize: '14px',
          textTransform: 'none',
          fontWeight: 400,
          letterSpacing: '0.5px',
        },
        sizeLarge: {
          padding: '5px 40px',
          fontSize: '14px',
          textTransform: 'none',
          fontWeight: 400,
          letterSpacing: '0.5px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          padding: '16px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '18px 16px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '0',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '220px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: '220px',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        input: {
          '&.Mui-disabled': {
            'text-fill-color': 'rgba(0, 0, 0) !important',
          },
          fontSize: '0.875rem'
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          '&.Mui-disabled': {
            display: 'none',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: '12px 0',
        },
      },
    },
  },
});

export default theme;
