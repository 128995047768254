import {
  VirtualAccountCreateDTO,
  VirtualAccountEntity,
} from 'api/payments-client';
import { Card, Title } from 'components';
import theme from 'config/theme';
import { useVirtualAccountSearch } from 'hooks';
import { useQuery } from 'hooks/useQuery';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CRUDPaginatedResponse } from 'types';
import { useDebounce } from 'use-debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { paymentsApiClient } from '../../api';
import { AddVirtualAccountWidget } from '../../widgets';
import { VirtualAccountsTable } from './VirtualAccountsTable';

const useStyles = makeStyles(
  (theme) => ({
    searchField: {
      '& .MuiOutlinedInput-root': {
        width: '300px',
      },
    },
    searchIcon: {
      color: theme.palette.grey['600'],
    },
  }),
  { defaultTheme: theme },
);

export const VirtualAccountsPage = () => {
  const classes = useStyles();
  const { tenantId } = useParams<{ tenantId: string }>();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const [debouncedSearch, controlFns] = useDebounce(search, 500);
  const loading = controlFns.isPending();
  const [open, setOpen] = useState(false);

  const { data: options } = useVirtualAccountSearch(debouncedSearch, tenantId);

  const { pushQuery, query } = useQuery(['pageSize', 'pageIndex']);

  const pageSize = !isNaN(Number(query.pageSize)) ? Number(query.pageSize) : 10;
  const pageIndex = !isNaN(Number(query.pageIndex))
    ? Number(query.pageIndex)
    : 0;
  const [rows, setRows] = useState<VirtualAccountEntity[]>([]);
  const [rowsCount, setRowsCount] = useState<number>(0);

  const fetchVirtualAccounts = useAsyncCallback(async () => {
    const { data } =
      await paymentsApiClient.api.getManyBaseVirtualAccountControllerVirtualAccountEntity(
        {
          filter: [`tenant.id||$eq||${tenantId}`],
          page: pageIndex + 1,
          limit: pageSize,
        },
      );
    setRows((data as CRUDPaginatedResponse<VirtualAccountEntity>)?.data ?? []);
    setRowsCount(
      (data as CRUDPaginatedResponse<VirtualAccountEntity>)?.total ?? 0,
    );
  });

  useDeepCompareEffect(() => {
    fetchVirtualAccounts.execute();
    // eslint-disable-next-line
  }, [query]);

  const handleFiltersChange = async (data: {
    filters: Array<{ id: string; value: string | string[] }>;
    pageSize: number;
    pageIndex: number;
  }) => {
    const query = {
      ...data.filters
        .map((e) => ({
          [e.id]: Array.isArray(e.value) ? e.value.join(',') : e.value,
        }))
        .reduce((acc, val) => ({ ...acc, ...val }), {}),
      pageSize: data.pageSize.toString(),
      pageIndex: data.pageIndex.toString(),
    };
    pushQuery(query);
  };

  const createAccount = useAsyncCallback(
    async (account: VirtualAccountCreateDTO) => {
      await paymentsApiClient.api.createOneBaseVirtualAccountControllerVirtualAccountEntity(
        {
          ...account,
          bankAccounts: account.bankAccounts?.length
            ? account.bankAccounts
            : undefined,
          tenant: tenantId,
        },
      );
      await fetchVirtualAccounts.execute();
    },
  );

  return (
    <Card>
      <Card.Body>
        <Title name="Virtual accounts">
          <AddVirtualAccountWidget onSubmit={createAccount.execute} />
        </Title>
        <VirtualAccountsTable
          rows={rows}
          onFiltersChange={handleFiltersChange}
          count={rowsCount}
          initialFilters={Object.keys(query)
            .filter((key) => key !== 'pageSize' && key !== 'pageIndex')
            .map((key) => {
              const value = query[key];
              return {
                id: key,
                value: Array.isArray(value) ? value : value?.split(','),
              };
            })}
          pageSize={pageSize}
          pageIndex={pageIndex}
          autocomplete={
            <Autocomplete
              id="quick-search"
              inputValue={search}
              onInputChange={(e, value) => setSearch(value)}
              open={open}
              onChange={(_, option) => {
                if (option?.id) history.push(`virtual-accounts/${option.id}`);
              }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={options}
              loading={loading}
              filterOptions={(options) => options} // Do NOT filter options
              getOptionLabel={(option) => {
                return `${option.id} | ${
                  option.contact?.isCompany
                    ? option.organizationName
                    : option.contact?.firstName + ' ' + option.contact?.lastName
                }`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Quick Search"
                  variant="outlined"
                  className={classes.searchField}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <FaSearch className={classes.searchIcon} />
                        )}
                      </>
                    ),
                  }}
                />
              )}
            />
          }
        />
      </Card.Body>
    </Card>
  );
};
