export enum Role {
  PaymentsViewOnly = 'payments:viewonly',
  PaymentsSuperAdmin = 'payments:superadmin',
}

export type CRUDPaginatedResponse<T> = {
  count: number;
  data: T[];
  page: number;
  pageCount: number;
  total: number;
};

export interface FilterFragment {
  id: string;
  value: string | (string | null)[] | null;
}

export interface SortFragment {
  id: string;
  desc?: boolean;
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface FilterData {
  filters?: FilterFragment[];
  sort?: SortFragment[];
  pageSize?: number;
  pageIndex?: number;
}

export * from './filter';
export { default as moment } from './moment';
