import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { paymentsApiClient } from './api';
import App from './App';
import { Loader } from './components';
import reportWebVitals from './reportWebVitals';
import AuthWrapper from './wrappers/AuthWrapper';
import theme from 'config/theme';
import { useAppConfig } from 'wrappers/ConfigLoaderWrapper';
import { Api as LedgerApi } from 'api/ledger-client';

console.log('ENV', process.env);

const PaymentsApp = () => {
  const {
    error,
    logout,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: auth0IsLoading,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function initApiClient() {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      paymentsApiClient.setSecurityData({ token: accessToken });
      window.ledgerApiClient.setSecurityData({ token: accessToken });
      setIsLoading(false);
    }

    if (isAuthenticated) {
      initApiClient();
    }
    if (!auth0IsLoading && !isAuthenticated) {
      setIsLoading(false);
    }
  }, [isAuthenticated, auth0IsLoading, getAccessTokenSilently]);

  useEffect(() => {
    if (error) {
      const handle = setTimeout(() => {
        logout();
      }, 3000);
      return () => clearTimeout(handle);
    }
  }, [error, logout]);

  if (isLoading) return <Loader />;

  if (error) {
    return (
      <div>
        <span>{`${error.name}:${error.message} Please use the company domain email and/or ask for access.`}</span>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthWrapper>
          <App />
          <ToastContainer />
        </AuthWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
};

declare global {
  interface Window {
    appConfig: any;
    ledgerApiClient: LedgerApi<{ token: string }>;
  }
}

const AppWithLocalizationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  );
};

const AppWithConfig = () => {
  const config = useAppConfig();
  if (!config) {
    return <div />;
  } else {
    console.log('Going to ' + window.appConfig.auth0domain);
    return (
      <Auth0Provider
        domain={window.appConfig.auth0domain}
        clientId={window.appConfig.auth0clientId}
        redirectUri={window.location.origin}
        audience={window.appConfig.audience}
        cacheLocation="localstorage"
      >
        <AppWithLocalizationProvider>
          <PaymentsApp />
        </AppWithLocalizationProvider>
      </Auth0Provider>
    );
  }
};

ReactDOM.render(
  <React.StrictMode>
    <AppWithConfig />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
