import { useState } from 'react';
import { Button } from '@mui/material';

import { PaymentCreateDTO } from 'api/payments-client';
import { FormDialog } from '../components';
import { AddPaymentForm } from '../forms';
import { useRoles } from 'hooks';
import { Currency, PaymentMethod, PaymentType } from 'types/payment';
import { FormikValues } from 'formik';
import { moment } from 'types';

type Props = {
  onSubmit: (arg0: PaymentCreateDTO) => Promise<void>;
  tenantId: string;
  initialValues?: Partial<PaymentCreateDTO>;
};

export const AddPaymentWidget = ({
  onSubmit,
  tenantId,
  initialValues,
}: Props) => {
  const { isSuperAdmin } = useRoles();
  const [isModalOpen, setModalOpen] = useState(false);

  if (!isSuperAdmin) return null;

  return (
    <div>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Add
      </Button>

      <FormDialog
        header="Add a new payment"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={async (values: FormikValues, helpers) => {
          try {
            const formattedValues = {
              ...values,
              amount: Math.round(+values.amount * 100),
            };

            await onSubmit(formattedValues as PaymentCreateDTO);
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          } finally {
            helpers?.setSubmitting(false);
          }
        }}
        initialValues={{
          type: PaymentType.PAYOUT,
          currency: Currency.CHF,
          method: PaymentMethod.BANK_TRANSFER,
          transferDueDate: moment()
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DD'),
          ...initialValues,
        }}
      >
        <AddPaymentForm
          tenantId={tenantId}
          virtualAccountDisabled={!!initialValues?.virtualAccountId}
        />
      </FormDialog>
    </div>
  );
};
