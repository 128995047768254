import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';
import {
  FilterValue,
  Row,
  TableInstance,
  TableOptions,
  useAsyncDebounce,
  useFilters,
  usePagination,
  useTable,
} from 'react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  Table as MUTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ToggleButton } from './ToggleButton';
import dayjs from 'dayjs';
import UTCDatePicker from './UTCDatePicker';

export interface TableProperties<T extends Record<string, unknown>>
  extends TableOptions<T> {
  name: string;
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>) => void;
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  count: number;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize?: number;
  pageIndex?: number;
  hiddenColumns?: Array<string>;
  autocomplete?: JSX.Element;
}

interface IColumnFilterProps {
  column: {
    filterValue?: string | Array<string>;
    setFilter: (
      updater: ((filterValue: FilterValue) => FilterValue) | FilterValue,
    ) => void;
  };
  setFilter: (columnId: string, filterValue: FilterValue) => void;
  filters?: string[];
  state: {
    filters: Array<FilterFragment>;
  };
}

const useStyles = makeStyles(() => ({
  quickFilters: {
    display: 'flex',
    '& > *:not(:first-child)': {
      marginLeft: '12px',
    },
  },
  quickFilterTitle: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  rangeFilterDefault: {
    minWidth: 30,
    maxWidth: 200,
    '& .MuiInputBase-root': { width: '85px' },
  },
  dateFilter: {
    '& .MuiOutlinedInput-root': { width: 155 },
    '& input': { padding: '8.5px 14px' },
  },
  selectFilter: {
    width: 'auto',
    minWidth: '50px',
    '& .MuiSelect-select': { height: '20.125px' },
  },
}));

export function DefaultColumnFilter({
  column: { filterValue, setFilter },
  ...props
}: IColumnFilterProps & TextFieldProps) {
  return (
    <TextField
      size="small"
      variant="outlined"
      sx={{ '& .MuiInputBase-root': { width: '180px' } }}
      placeholder={`Search records...`}
      {...props}
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value ?? undefined);
      }}
    />
  );
}

export function SelectColumnFilter({
  column: { filterValue = [], setFilter },
  filters,
  ...props
}: IColumnFilterProps & SelectProps) {
  const classes = useStyles();
  return (
    <Select
      multiple
      size="small"
      variant="outlined"
      sx={{ '& .MuiInputBase-root': { width: '180px' } }}
      className={classes.selectFilter}
      {...props}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
    >
      {filters?.map((e) => (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      ))}
    </Select>
  );
}
interface RangeProps {
  leftId: string;
  rightId: string;
}

interface DateProps {
  id: string;
}

export function RangeColumnFilter({
  leftId,
  rightId,
  setFilter,
  state,
}: IColumnFilterProps & RangeProps) {
  const classes = useStyles();

  const minFilterValue = state?.filters.find(
    (e: { id: string }) => e.id === leftId,
  )?.value;
  const maxFilterValue = state?.filters.find(
    (e: { id: string }) => e.id === rightId,
  )?.value;

  return (
    <Grid container direction="row" wrap="nowrap">
      <TextField
        size="small"
        variant="outlined"
        value={minFilterValue ?? ''}
        onChange={(e) => {
          setFilter(leftId, e.target.value);
        }}
        className={classes.rangeFilterDefault}
      />
      <TextField
        size="small"
        variant="outlined"
        value={maxFilterValue ?? ''}
        onChange={(e) => {
          setFilter(rightId, e.target.value);
        }}
        className={classes.rangeFilterDefault}
      />
    </Grid>
  );
}

export function DateColumnFilter({
  leftId,
  rightId,
  setFilter,
  state,
}: IColumnFilterProps & RangeProps) {
  const classes = useStyles();
  const minFilterValue =
    state?.filters.find((e: { id: string }) => e.id === leftId)?.value ?? null;
  const maxFilterValue =
    state?.filters.find((e: { id: string }) => e.id === rightId)?.value ?? null;

  return (
    <Grid container direction="row" wrap="nowrap">
      <UTCDatePicker
        key={leftId}
        value={minFilterValue ? dayjs(minFilterValue) : null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (dayjs(e.target.value).isValid() || !e.target.value) {
            setFilter(leftId, e.target.value);
          }
        }}
        className={classes.dateFilter}
      />

      <UTCDatePicker
        key={rightId}
        value={maxFilterValue ? dayjs(maxFilterValue) : null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (dayjs(e.target.value).isValid() || !e.target.value) {
            setFilter(rightId, e.target.value);
          }
        }}
        className={classes.dateFilter}
      />
    </Grid>
  );
}

export function DateFilter({
  setFilter,
  state,
  id,
}: IColumnFilterProps & DateProps) {
  const classes = useStyles();
  const filterValue =
    state?.filters.find((e: { id: string }) => e.id === id)?.value ?? null;

  return (
    <Grid container direction="row" wrap="nowrap">
      <UTCDatePicker
        key={id}
        value={filterValue ? dayjs(filterValue) : null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (dayjs(e.target.value).isValid() || !e.target.value) {
            setFilter(id, e.target.value);
          }
        }}
        className={classes.dateFilter}
      />
    </Grid>
  );
}

export function Table<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>,
): ReactElement {
  const {
    initialFilters = [],
    pageSize,
    pageIndex,
    columns,
    data,
    onFiltersChange,
    count,
    quickFilters,
    hiddenColumns = [],
    autocomplete,
  } = props;
  const classes = useStyles();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    gotoPage,
    setPageSize,
    setFilter,
    setAllFilters,
  } = useTable<T>(
    {
      columns,
      data,
      useControlledState: (state: any) => {
        return React.useMemo(
          () => ({
            ...state,
            pageCount: count / state.pageSize,
          }),
          [state],
        );
      },
      initialState: {
        pageSize,
        pageIndex,
        filters: initialFilters,
        hiddenColumns,
      },
      defaultColumn,
      manualPagination: true,
      manualFilters: true,
      pageCount: Math.ceil(count / 10),
    },
    useFilters,
    usePagination,
  );

  const onFiltersChangeDebounced = useAsyncDebounce(onFiltersChange, 500);

  useDeepCompareEffect(() => {
    onFiltersChangeDebounced({
      filters: state.filters,
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
    });
  }, [state.filters, state.pageSize, state.pageIndex]);

  useDeepCompareEffect(() => {
    initialFilters.forEach((e: FilterFragment) => {
      setFilter(e.id, e.value);
    });

    onFiltersChangeDebounced({
      filters: initialFilters,
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
    });
  }, [initialFilters]);

  const getQuickFilterValue = (id: string, value: string): boolean => {
    const filterObj = state.filters.find((el) => el.id === id);
    return !!filterObj?.value.find((el: string) => el === value);
  };

  return (
    <>
      {(quickFilters || autocomplete) && (
        <Grid container justifyContent="space-between">
          {quickFilters ? (
            <div className={classes.quickFilters}>
              <h3 className={classes.quickFilterTitle}>Quick filters</h3>
              {quickFilters.values.map((el) => {
                return (
                  <ToggleButton
                    key={el.value}
                    val={getQuickFilterValue(quickFilters.id, el.value)}
                    onValueChange={(value) => {
                      setFilter(
                        quickFilters.id,
                        !value
                          ? state?.filters
                              ?.find((el) => el.id === quickFilters.id)
                              ?.value?.filter(
                                (value: string) => value !== el.value,
                              )
                          : [
                              ...(state.filters.find(
                                (el) => el.id === quickFilters.id,
                              )?.value ?? []),
                              el.value,
                            ],
                      );
                    }}
                  >
                    {el.label}
                  </ToggleButton>
                );
              })}
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setAllFilters([]);
                }}
              >
                Clear filters
              </Button>
            </div>
          ) : (
            <div />
          )}
          {autocomplete}
        </Grid>
      )}
      <TableContainer>
        <MUTable>
          <TableHead>
            {headerGroups.map((headerGroup) => {
              const { key: groupKey, ...restGroupProps } = {
                ...headerGroup.getHeaderGroupProps(),
              };
              return (
                <TableRow key={groupKey} {...restGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key: cellKey, ...restCellProps } = {
                      ...column.getHeaderProps(),
                    };
                    return (
                      <TableCell key={cellKey} {...restCellProps}>
                        {column.render('Header')}
                        <div>
                          {column.canFilter && column.Filter
                            ? column.render('Filter')
                            : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const { key, ...getRowProps } = row.getRowProps();
              return (
                <TableRow key={key} {...getRowProps}>
                  {row.cells.map((cell, j) => {
                    const { key, ...getCellProps } = cell.getCellProps();
                    return (
                      <TableCell key={key} {...getCellProps}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUTable>

        <TablePagination
          component="div"
          count={count}
          page={state.pageIndex}
          onPageChange={(event, page) => gotoPage(page)}
          rowsPerPage={state.pageSize}
          onRowsPerPageChange={(event) => setPageSize(+event.target.value)}
        />
      </TableContainer>
    </>
  );
}

interface FilterFragment {
  id: string;
  value: any;
}
