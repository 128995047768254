import { paymentsApiClient } from 'api';
import { Card, Title } from 'components';
import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { ProcessesTable } from './ProcessesTable';
import { useAsyncCallback } from 'react-async-hook';

export const ProcessesPage = () => {
  const [rows, setRows] = useState<any[]>([]);
  const fetchProcesses = async () => {
    const { data } =
      await paymentsApiClient.api.processControllerGetProcesses();
    setRows(data ?? []);
  };

  useEffect(() => {
    fetchProcesses();
  }, []);

  const onRun = useAsyncCallback(async (name: string) => {
    await paymentsApiClient.api.processControllerStartProcess(name);
    fetchProcesses();
  });

  return (
    <Card>
      <Card.Body>
        <Title name="Processes" />
        <Grid container justifyContent="flex-end">
          <Grid item md={5}></Grid>
        </Grid>
        <ProcessesTable rows={rows} onRun={onRun.execute} />
      </Card.Body>
    </Card>
  );
};
