import {useEffect, useState} from "react";
import * as CryptoJS from 'crypto-js';
import { createLedgerApiClient } from "api";

export const baseUrl = (() => {
    let url = 'http://localhost:3002'
    if (process.env.NODE_ENV === 'production') {
        url = ''
    }
    console.log("baseurl: " + url)
    return url
})()

export function useAppConfig(){
    const [configLocal, setConfig] = useState<Record<string, any> | undefined>(undefined);

    useEffect(() => {
        const url = baseUrl + '/api/frontend_config'
        fetch(url)
            .then(result => result.text())
            .then(result => CryptoJS.AES.decrypt(result, 'not_a_secret').toString(CryptoJS.enc.Utf8))
            .then(result => JSON.parse(JSON.parse(result)))
            .then(json => {
                console.log("setting app config")
                console.log(json)
                window.appConfig = json
                createLedgerApiClient()
                setConfig(json)
            })
    }, [])

    return configLocal
}