import { paymentsApiClient } from 'api';
import { TransactionEntity } from 'api/payments-client';
import { Card, Input, Select, Title } from 'components';
import theme from 'config/theme';
import { moment } from 'types';
import { Field, Form, Formik } from 'formik';
import { useVirtualAccountSearch } from 'hooks';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Address } from 'types/address';
import { MatchState, PaymentType, TransactionStatus } from 'types/payment';
import { useDebounce } from 'use-debounce/lib';
import { downloadXML, toTwoDecimals } from 'utils';
import { useRoles } from 'hooks';

import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    input: {
      width: '100%',
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
    block: {
      position: 'relative',
    },
    searchField: {
      '& .MuiOutlinedInput-root': {
        width: '240px',
      },
    },
    searchIcon: {
      color: theme.palette.grey['600'],
    },
  }),
  { defaultTheme: theme },
);

export const TransactionsDetailsPage = () => {
  const classes = useStyles();
  const { isSuperAdmin } = useRoles();
  const { transactionId, tenantId } =
    useParams<{ transactionId: string; tenantId: string }>();

  const {
    result: transaction,
    loading,
    error,
    execute,
  } = useAsync(async () => {
    const { data } =
      await paymentsApiClient.api.transactionControllerGetTransaction(
        transactionId,
      );
    return data;
  }, [transactionId]);

  const [search, setSearch] = useState('');
  const [debouncedSearch, controlFns] = useDebounce(search, 500);
  const vaLoading = controlFns.isPending();
  const [open, setOpen] = useState(false);

  const { data: options } = useVirtualAccountSearch(debouncedSearch, tenantId);

  const match = async (transaction: TransactionEntity, search: string) => {
    await paymentsApiClient.api.transactionControllerMatch(
      transaction.transactionId,
      search,
    );
    await execute();
  };

  const unmatch = async (transaction: TransactionEntity) => {
    await paymentsApiClient.api.transactionControllerUnmatch(
      transaction.transactionId,
    );
    await execute();
  };

  if (loading) {
    return <CircularProgress size={40} />;
  }

  if (error || !transaction) {
    return <div>Transaction not found</div>;
  }

  return (
    <Card>
      <Card.Body>
        <Title name={`Transaction ${transaction.transactionId}`} />
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Formik
              enableReinitialize
              initialValues={
                {
                  ...transaction,
                  bookingDate: transaction.bookingDate
                    ? moment(transaction.bookingDate).format('DD.MM.YYYY')
                    : '',
                  valueDate: transaction.valueDate
                    ? moment(transaction.valueDate).format('DD.MM.YYYY')
                    : '',
                  manualMatchDate: transaction?.manualMatchDate
                    ? moment(transaction.manualMatchDate).format('DD.MM.YYYY')
                    : '',
                  amount: toTwoDecimals(transaction.amount),
                } as unknown as TransactionEntity
              }
              onSubmit={async (values: TransactionEntity) => {
                await paymentsApiClient.api.transactionControllerUpdate({
                  transactionId: values.transactionId,
                  status: values.status,
                });
                await execute();
              }}
            >
              {({ values }) => {
                const clientAddress = (
                  transaction.type === PaymentType.PAYIN
                    ? transaction.debtorAddress
                    : transaction.creditorAddress
                ) as Address;

                const clientBankName =
                  transaction.type === PaymentType.PAYIN
                    ? transaction.debtorAgentName
                    : transaction.creditorAgentName;

                const clientBankAddress = (
                  transaction.type === PaymentType.PAYIN
                    ? transaction.debtorAgentAddress
                    : transaction.creditorAgentAddress
                ) as Address;

                const clientIban =
                  transaction.type === PaymentType.PAYIN
                    ? transaction.debtorIban
                    : transaction.creditorIban;

                return (
                  <Form>
                    <Paper elevation={3}>
                      <Typography variant="subtitle1">
                        Transaction details
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} className={classes.block}>
                          <List>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="transactionId"
                                name="transactionId"
                                label="Transaction ID"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="type"
                                name="type"
                                label="Type"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="currency"
                                name="currency"
                                label="Currency"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="amount"
                                name="amount"
                                label="Amount"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="date"
                                name="bookingDate"
                                label="Date"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="matchState"
                                name="matchState"
                                label="Matching"
                                labelDirection="row"
                                component={Select}
                                values={Object.values(MatchState).map((e) => ({
                                  value: e,
                                  label: e,
                                }))}
                                md={4}
                                className={classes.input}
                              />
                              {values.matchState === MatchState.Matched &&
                                isSuperAdmin && (
                                  <Button
                                    onClick={async () => unmatch(transaction)}
                                  >
                                    Unmatch
                                  </Button>
                                )}
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled={!isSuperAdmin}
                                id="status"
                                name="status"
                                label="Status"
                                labelDirection="row"
                                component={Select}
                                values={Object.values(TransactionStatus).map(
                                  (e) => ({
                                    value: e,
                                    label: e,
                                  }),
                                )}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="reference"
                                name="referenceNumber"
                                label="Reference"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="clientName"
                                name="clientName"
                                label="Customer name"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="cg24Account"
                                name="bankIban"
                                label="CG24 Iban"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="additional"
                                name="additional"
                                label="Additional information"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="valueDate"
                                name="valueDate"
                                label="Value date"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="customerAddress"
                                label="Customer address"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                                value={`${clientAddress?.street ?? ''} ${
                                  clientAddress?.streetNr ?? ''
                                } ${clientAddress?.city ?? ''} ${
                                  clientAddress?.zipcode ?? ''
                                } ${clientAddress?.country?.name ?? ''}`}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="bankName"
                                name="bankName"
                                label="Bank Name"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                                value={clientBankName}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="bankAddress"
                                label="Bank Address"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                                value={`${clientBankAddress?.street ?? ''} ${
                                  clientBankAddress?.streetNr ?? ''
                                } ${clientBankAddress?.city ?? ''} ${
                                  clientBankAddress?.zipcode ?? ''
                                } ${clientBankAddress?.country?.name ?? ''}`}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="clientIban"
                                name="clientIban"
                                label="Client IBAN"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                                value={clientIban}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="endToEndId"
                                name="endToEndId"
                                label="End To End Id"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="manualMatchBy"
                                name="manualMatchBy"
                                label="Manual Match By"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="manualMatchDate"
                                name="manualMatchDate"
                                label="Manual Match Date"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="manualMatchTo"
                                name="manualMatchTo"
                                label="Manual Match To"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                              />
                            </ListItem>
                            <ListItem>
                              <>
                                <Autocomplete
                                  id={`va-search-${transaction.transactionId}`}
                                  inputValue={search}
                                  onInputChange={(e, value) => setSearch(value)}
                                  open={open}
                                  onOpen={() => setOpen(true)}
                                  onClose={() => setOpen(false)}
                                  options={options}
                                  loading={vaLoading}
                                  filterOptions={(options) => options} // Do NOT filter options
                                  getOptionLabel={(option) => {
                                    return `${option.id}`;
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="VA Search"
                                      variant="outlined"
                                      className={classes.searchField}
                                      size="small"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {loading ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : (
                                              <FaSearch
                                                className={classes.searchIcon}
                                              />
                                            )}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {isSuperAdmin && (
                                  <Button
                                    onClick={async () =>
                                      match(transaction, search)
                                    }
                                  >
                                    Match
                                  </Button>
                                )}
                              </>
                            </ListItem>
                            <ListItem divider disableGutters>
                              <Field
                                disabled
                                id="reversal"
                                name="reversal"
                                label="Reversal"
                                labelDirection="row"
                                component={Input}
                                md={4}
                                className={classes.input}
                                value={values.reversal ? 'Yes' : 'No'}
                              />
                            </ListItem>
                            {isSuperAdmin && (
                              <ListItem divider disableGutters>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    downloadXML(
                                      transaction.rawEntry,
                                      transaction.transactionId,
                                    )
                                  }
                                >
                                  Download raw data
                                </Button>
                              </ListItem>
                            )}
                            {isSuperAdmin && (
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Save
                              </Button>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
