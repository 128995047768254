import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { IconButton, List, ListItem, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaEdit, FaSave, FaWindowClose } from 'react-icons/fa';
import { VirtualAccountEntity, VirtualAccountUpdateDTO } from "api/payments-client"
import { Input, Select } from 'components';
import { groupValidators, notEmpty, validEmail } from 'utils/validation';
import { useRoles } from 'hooks';
import { useCountryList } from '../../../hooks/useCountryList';

const useStyles = makeStyles(() => ({
  block: {
    position: 'relative',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  iconsBlock: {
    position: 'absolute',
    top: -30,
    right: 0,
    '& > *': {
      padding: 0,
    },
    '& > *:not(:first-child)': {
      margin: '0 0 0 8px',
    },
  }
}));

type Props = {
  virtualAccount: VirtualAccountEntity;
  onSave: (arg0: VirtualAccountUpdateDTO) => void;
}

export const ContactForm = ({ virtualAccount, onSave }: Props) => {
  const classes = useStyles();
  const { isSuperAdmin } = useRoles();
  const countryList = useCountryList(true);
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <Paper elevation={3}>
      <Typography variant="subtitle1">Contact details</Typography>
      <Formik
        enableReinitialize
        initialValues={virtualAccount}
        onSubmit={(values) => onSave({
          contact: values.contact
        })}
      >
        {({ handleReset }) => (
          <Form>
            <List>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.firstName"
                  name="contact.firstName"
                  label="First Name"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.lastName"
                  name="contact.lastName"
                  label="Last Name"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.address.street"
                  name="contact.address.street"
                  label="Street"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.address.city"
                  name="contact.address.city"
                  label="City"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.address.zipcode"
                  name="contact.address.zipcode"
                  label="Zip Code"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.address.country"
                  name="contact.address.country"
                  label="Country"
                  labelDirection="row"
                  component={Select}
                  values={countryList}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.address.streetNr"
                  name="contact.address.streetNr"
                  label="Street Nt"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.isCompany"
                  name="contact.isCompany"
                  label="Company"
                  labelDirection="row"
                  component={Select}
                  values={[
                    { value: true, label: 'Contact is company' },
                    { value: false, label: 'Contact is not company' },
                  ]}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="contact.email"
                  name="contact.email"
                  label="Email"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={groupValidators(notEmpty, validEmail)}
                />
              </ListItem>
              {isSuperAdmin && (
                <div className={classes.iconsBlock}>
                  {isDisabled && (
                    <IconButton type="button">
                      <FaEdit
                        size="24"
                        onClick={() => setIsDisabled(false)}
                      />
                    </IconButton>
                  )}
                  {!isDisabled && (
                    <IconButton type="submit" color="primary">
                      <FaSave size="24" />
                    </IconButton>
                  )}
                  {!isDisabled && (
                    <IconButton type="button">
                      <FaWindowClose
                        size="24"
                        onClick={() => {
                          handleReset();
                          setIsDisabled(true);
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
            </List>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}