import { useState } from 'react';
import { toTwoDecimals } from 'utils';

import { Grid, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BalanceDto } from 'api/ledger-client';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    cursor: 'pointer',
  },
});

export const BalanceReportItem = ({
  node,
}: {
  node?: { EUR: BalanceDto; CHF: BalanceDto };
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem divider disableGutters onClick={handleClick}>
        <Grid container>
          <Grid xs={6} className={classes.item}>
            <Typography variant="subtitle1">{'CHF'}</Typography>
          </Grid>
          <Grid xs={6} className={classes.item}>
            <span style={{ marginLeft: '.8rem' }}>
              {toTwoDecimals(node?.CHF?.amount ?? 0)}
            </span>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider disableGutters onClick={handleClick}>
        <Grid container>
          <Grid xs={6} className={classes.item}>
            <Typography variant="subtitle1">{'EUR'}</Typography>
          </Grid>
          <Grid xs={6} className={classes.item}>
            <span style={{ marginLeft: '.8rem' }}>
              {toTwoDecimals(node?.EUR?.amount ?? 0)}
            </span>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};
