import { VirtualAccountCreateDTO } from 'api/payments-client';
import { useState } from 'react';
import { Button } from '@mui/material';

import { useRoles } from 'hooks';
import { FormDialog } from '../components';
import { AddVirtualAccountForm } from '../forms';
import { FormikValues } from 'formik';

type Props = {
  onSubmit: (arg0: VirtualAccountCreateDTO) => Promise<void>;
};

export const AddVirtualAccountWidget = ({ onSubmit }: Props) => {
  const { isSuperAdmin } = useRoles();
  const [isModalOpen, setModalOpen] = useState(false);

  if (!isSuperAdmin) return null;

  return (
    <div>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Add
      </Button>
      <FormDialog
        header="Add a new virtual account"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={async (values: FormikValues, helpers) => {
          try {
            const dto = {
              name: values.name,
              tenant: values.tenant,
              organizationName: values.organizationName || '-',
              bankAccounts: values.bankAccounts,
              contact: values.contact,
              ...(values.tagKey && values.tagValue
                ? {
                    tags: {
                      [values.tagKey]: values.tagValue,
                    },
                  }
                : {}),
            };

            await onSubmit(dto as VirtualAccountCreateDTO);
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          } finally {
            helpers?.setSubmitting(false);
          }
        }}
      >
        <AddVirtualAccountForm />
      </FormDialog>
    </div>
  );
};
