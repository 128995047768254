import { BankStatementEntity } from 'api/payments-client';
import { DateFilter, Table } from 'components';
import { CellProps } from 'react-table';
import { toTwoDecimals } from 'utils';

import { useMemo } from 'react';
import { moment } from 'types';

type BankStatementType = { [key: string]: any } & BankStatementEntity;

interface FilterFragment {
  id: string;
  value: any;
}

interface BankStatementsTableProps {
  rows: BankStatementEntity[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value?: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize: number;
  pageIndex: number;
  count: number;
  autocomplete?: JSX.Element;
}

export function BankStatementsTable({ ...props }: BankStatementsTableProps) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'statementId',
      },
      {
        Header: 'IBAN',
        accessor: 'iban',
      },
      {
        Header: 'Sum of Transactions',
        accessor: 'sumOfTransactions',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return toTwoDecimals(value);
        },
      },
      {
        Header: 'Opening balance',
        accessor: 'openingBalance',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return toTwoDecimals(value);
        },
      },
      {
        Header: 'Closing balance',
        accessor: 'closingBalance',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return toTwoDecimals(value);
        },
      },
      {
        Header: 'From Date',
        accessor: 'fromDate',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return moment(value).format('DD.MM.YYYY');
        },
        Filter: function Filter(otherProps: any) {
          return <DateFilter {...otherProps} id="fromDate" />;
        },
      },
      {
        Header: 'To Date',
        accessor: 'toDate',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return moment(value).format('DD.MM.YYYY');
        },
        Filter: function Filter(otherProps: any) {
          return <DateFilter {...otherProps} id="toDate" />;
        },
      },
      {
        Header: 'Created',
        accessor: 'meta.created',
        Cell: function Cell({ value }: CellProps<BankStatementType>) {
          return moment(value).format('DD.MM.YYYY hh:mm:ss');
        },
        Filter: undefined,
      },
    ],
    [],
  );

  return (
    <Table<BankStatementType>
      name="BankStatementTable Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.rows}
      columns={columns}
      count={props.count}
      quickFilters={props.quickFilters}
      autocomplete={props.autocomplete}
    />
  );
}
