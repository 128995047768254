import 'react-pro-sidebar/dist/css/styles.css';

import { CgProfile } from 'react-icons/cg';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { MdOutlineAccountTree, MdPayments } from 'react-icons/md';
import { FcProcess } from 'react-icons/fc';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { ActiveLink } from './ActiveLink';
import { makeStyles } from '@mui/styles';
import { Dispatch, SetStateAction } from 'react';
import { Drawer, Typography } from '@mui/material';

const useStyles = makeStyles({
  drawerPaper: {
    padding: 0,
    width: '260px',
  },

  title: {
    color: '#fff',
    fontSize: '20px',
    padding: '0 20px',
  },

  proSidebar: {
    height: '100vh',
    width: '260px',
    minWidth: '260px',
    position: 'fixed',
    '& .pro-sidebar-inner': {
      backgroundColor: '#1a4063',
    },

    '& .pro-icon-wrapper': {
      color: 'white',
      background: 'transparent !important',
    },

    '& .pro-menu a': {
      '&::before': {
        position: 'relative',
      },
    },

    '& .active': {
      backgroundColor: '#3a77b0',
    },
  },
});

export const Sidebar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      variant={'persistent'}
    >
      <ProSidebar collapsed={false} className={classes.proSidebar}>
        <SidebarHeader>
          <div>
            <Link to="/tenants">
              <p className={classes.title}>Payments | Admin</p>
            </Link>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <ActiveLink href="/tenants" icon={<FaFileInvoiceDollar />}>
              Tenants
            </ActiveLink>
          </Menu>
        </SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={
              user?.picture ? (
                <img
                  src={user?.picture}
                  alt="User"
                  height="35px"
                  width="35px"
                />
              ) : (
                <CgProfile size="100" />
              )
            }
          >
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              <SidebarFooter>
                <Typography component="div">{user?.name}</Typography>
                <Typography component="div">{user?.email}</Typography>
              </SidebarFooter>
            </div>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </Drawer>
  );
};

export const TenantSidebar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user, logout } = useAuth0();
  const classes = useStyles();
  const { url } = useRouteMatch();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      variant={'persistent'}
    >
      <ProSidebar collapsed={false} className={classes.proSidebar}>
        <SidebarHeader>
          <div>
            <Link to="/tenants">
              <p className={classes.title}>Payments | Admin</p>
            </Link>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <ActiveLink
              href={`${url}/virtual-accounts`}
              icon={<MdOutlineAccountTree />}
            >
              Virtual Accounts
            </ActiveLink>
            <ActiveLink href={`${url}/payments`} icon={<MdPayments />}>
              Payments
            </ActiveLink>
            <ActiveLink href={`${url}/lsv-files`} icon={<MdPayments />}>
              LSV Files
            </ActiveLink>
            <ActiveLink href={`${url}/processes`} icon={<FcProcess />}>
              Processes
            </ActiveLink>
            <ActiveLink href={`${url}/transactions`} icon={<MdPayments />}>
              Transactions
            </ActiveLink>
            <ActiveLink href={`${url}/bank-statements`} icon={<MdPayments />}>
              <span title="PostFinance Bank Statements (CAMT.053)">
                PostFinance Bank Statements (CAMT.053)
              </span>
            </ActiveLink>
            <ActiveLink href={`${url}/checks`} icon={<MdPayments />}>
              Checks
            </ActiveLink>
          </Menu>
        </SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={
              user?.picture ? (
                <img
                  src={user?.picture}
                  alt="User"
                  height="35px"
                  width="35px"
                />
              ) : (
                <CgProfile size="100" />
              )
            }
          >
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              <SidebarFooter>
                <Typography component="div">{user?.name}</Typography>
                <Typography component="div">{user?.email}</Typography>
              </SidebarFooter>
            </div>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </Drawer>
  );
};
