import { BankStatementsPage } from 'pages/BankStatementsPage/BankStatementsPage';
import { ChecksPage } from 'pages/ChecksPage/ChecksPage';
import { ProcessesPage } from 'pages/ProcessesPage/ProcessesPage';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { TenantLayout } from './components';
import {
  PaymentsDetailsPage,
  PaymentsPage,
  TenantsPage,
  TransactionsDetailsPage,
  TransactionsPage,
  VirtualAccountDetailsPage,
  VirtualAccountsPage,
  LSVFilesPage,
} from './pages';

const TenantRouter = () => {
  const { path } = useRouteMatch();

  return (
    <TenantLayout>
      <Switch>
        <Route exact path={`${path}/virtual-accounts`}>
          <VirtualAccountsPage />
        </Route>
        <Route path={`${path}/virtual-accounts/:id`}>
          <VirtualAccountDetailsPage />
        </Route>
        <Route exact path={`${path}/payments`}>
          <PaymentsPage />
        </Route>
        <Route exact path={`${path}/lsv-files`}>
          <LSVFilesPage />
        </Route>
        <Route exact path={`${path}/processes`}>
          <ProcessesPage />
        </Route>
        <Route path={`${path}/payments/:id`}>
          <PaymentsDetailsPage />
        </Route>
        <Route exact path={`${path}/transactions`}>
          <TransactionsPage />
        </Route>
        <Route path={`${path}/transactions/:transactionId`}>
          <TransactionsDetailsPage />
        </Route>
        <Route exact path={`${path}/bank-statements`}>
          <BankStatementsPage />
        </Route>
        <Route exact path={`${path}/checks`}>
          <ChecksPage />
        </Route>
      </Switch>
    </TenantLayout>
  );
};

function App() {
  return (
    <Switch>
      <Route exact path="/tenants">
        <TenantsPage />
      </Route>
      <Route path="/tenants/:tenantId">
        <TenantRouter />
      </Route>

      {/* REDIRECT TO TENANTS PAGE IF THERE IS NO MATCH */}
      <Route>
        <Redirect to="/tenants"></Redirect>
      </Route>
    </Switch>
  );
}

export default App;
