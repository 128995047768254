import { useState } from 'react';

import { Button, CircularProgress } from '@mui/material';

type Props = {
  children: React.ReactNode;
  onClick: () => Promise<void>;
};

export const DownloadButton = ({ children, onClick }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <Button onClick={handleClick} disabled={loading}>
      {loading ? <CircularProgress size="1.5em" /> : children}
    </Button>
  );
};
