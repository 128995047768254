import { TransactionDto } from 'api/ledger-client';

export const getAccount = (transaction: TransactionDto): string => {
  let account = transaction.acc1 ?? '';
  if (transaction.acc2) account += ':' + transaction.acc2;
  if (transaction.acc3) account += ':' + transaction.acc3;
  if (transaction.acc4) account += ':' + transaction.acc4;
  if (transaction.acc5) account += ':' + transaction.acc5;

  return account;
};
