import { paymentsApiClient } from 'api';
import { PaymentDTO } from 'api/payments-client';
import {
  DateColumnFilter,
  DownloadButton,
  RangeColumnFilter,
  SelectColumnFilter,
  Table,
} from 'components';
import { FaDownload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import {
  Currency,
  PaymentMethod,
  PaymentStatus,
  PaymentType as PaymentTypeEnum,
} from 'types/payment';
import { toTwoDecimals } from 'utils';
import { downloadPDF } from 'utils';

import { Button, Chip } from '@mui/material';
import { useMemo } from 'react';
import { moment } from 'types';

type PaymentType = { [key: string]: any } & PaymentDTO;

interface FilterFragment {
  id: string;
  value: any;
}

interface PaymentsTableProps {
  rows: PaymentDTO[];
  onFiltersChange: (data: {
    filters: FilterFragment[];
    pageSize: number;
    pageIndex: number;
  }) => void;
  initialFilters: Array<{ id: string; value?: string | string[] }>;
  quickFilters?: {
    id: string;
    values: Array<{ label: string; value: string }>;
  };
  pageSize: number;
  pageIndex: number;
  count: number;
  markAsPaid: (arg0: PaymentDTO) => Promise<void>;
  autocomplete?: JSX.Element;
}

export function PaymentsTable({ markAsPaid, ...props }: PaymentsTableProps) {
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'minCreationDate',
      },
      {
        Header: '',
        accessor: 'maxCreationDate',
      },
      {
        Header: 'Creation date',
        accessor: 'meta.created',
        Cell: function Cell({ value }: CellProps<PaymentType>) {
          return moment(value).format('DD.MM.YYYY');
        },
        Filter: function Filter(otherProps: any) {
          return (
            <DateColumnFilter
              {...otherProps}
              leftId="minCreationDate"
              rightId="maxCreationDate"
            />
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: function Cell({ row: { original } }: CellProps<PaymentType>) {
          return (
            <Chip
              label={original.id}
              onClick={() => history.push(`payments/${original.id}`)}
            />
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              filters={Object.values(PaymentTypeEnum)}
            />
          );
        },
      },
      {
        Header: 'Method',
        accessor: 'method',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              filters={Object.values(PaymentMethod)}
            />
          );
        },
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Virtual account',
        accessor: 'virtualAccountId',
        Cell: function Cell({ row: { original } }: CellProps<PaymentType>) {
          return (
            <Chip
              label={
                original.contact
                  ? `${original.virtualAccountId} | ${
                      original.contact?.isCompany
                        ? original.virtualAccount.organizationName
                        : original.contact?.firstName +
                          ' ' +
                          original.contact?.lastName
                    }`
                  : original.virtualAccountId
              }
              onClick={() =>
                history.push(`virtual-accounts/${original.virtualAccountId}`)
              }
            />
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              filters={Object.values(PaymentStatus)}
            />
          );
        },
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Filter: function Filter(otherProps: any) {
          return (
            <SelectColumnFilter
              {...otherProps}
              filters={Object.values(Currency)}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'minAmount',
      },
      {
        Header: '',
        accessor: 'maxAmount',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: function Cell({ value }: CellProps<PaymentType>) {
          return toTwoDecimals(value);
        },
        Filter: function Filter(otherProps: any) {
          return (
            <RangeColumnFilter
              {...otherProps}
              leftId="minAmount"
              rightId="maxAmount"
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'markAsPaid',
        Filter: undefined,
        Cell: function Cell({ row: { original } }: CellProps<PaymentType>) {
          if (
            original.status === PaymentStatus.OPEN &&
            original.method === PaymentMethod.CASH
          ) {
            return (
              <Button onClick={() => markAsPaid(original)}>Mark as paid</Button>
            );
          }

          return null;
        },
      },
      {
        Header: '',
        accessor: 'download',
        Filter: undefined,
        Cell: function Cell({ row: { original } }: CellProps<PaymentType>) {
          return original.type === PaymentTypeEnum.PAYIN ? (
            <DownloadButton
              onClick={async () => {
                const { data } =
                  await paymentsApiClient.api.paymentControllerGetPdfInvoice(
                    original.id,
                  );
                if (data) {
                  downloadPDF(data?.content, data?.name);
                }
              }}
            >
              <FaDownload />
            </DownloadButton>
          ) : (
            ''
          );
        },
      },
    ],
    [markAsPaid, history],
  );

  const hiddenColumns = [
    'minAmount',
    'maxAmount',
    'minCreationDate',
    'maxCreationDate',
  ];

  return (
    <Table<PaymentType>
      name="Payments Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.rows}
      columns={columns}
      count={props.count}
      hiddenColumns={hiddenColumns}
      quickFilters={props.quickFilters}
      autocomplete={props.autocomplete}
    />
  );
}
