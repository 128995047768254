import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { IconButton, List, ListItem, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaEdit, FaSave, FaWindowClose } from 'react-icons/fa';
import { VirtualAccountEntity, VirtualAccountUpdateDTO } from 'api/payments-client'
import { Input } from 'components';
import { notEmpty } from 'utils/validation';
import { useRoles } from 'hooks';

const useStyles = makeStyles(() => ({
  block: {
    position: 'relative',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  iconsBlock: {
    position: 'absolute',
    top: -30,
    right: 0,
    '& > *': {
      padding: 0,
    },
    '& > *:not(:first-child)': {
      margin: '0 0 0 8px',
    },
  }
}));

type Props = {
  virtualAccount: VirtualAccountEntity;
  onSave: (arg0: VirtualAccountUpdateDTO) => void;
}

export const DetailsForm = ({ virtualAccount, onSave }: Props) => {
  const classes = useStyles();
  const { isSuperAdmin } = useRoles();
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <Paper elevation={3}>
      <Typography variant="subtitle1">Account details</Typography>
      <Formik
        enableReinitialize
        initialValues={virtualAccount}
        onSubmit={(values) => onSave({
          organizationName: values.organizationName
        })}
      >
        {({ handleReset }) => (
          <Form>
            <List>
              <ListItem divider disableGutters>
                <Field
                  disabled
                  id="id"
                  name="id"
                  label="ID"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                />
              </ListItem>
              <ListItem divider disableGutters>
                <Field
                  required={!isDisabled}
                  disabled={isDisabled}
                  id="organizationName"
                  name="organizationName"
                  label="Organization Name"
                  labelDirection="row"
                  component={Input}
                  md={4}
                  className={classes.input}
                  validate={notEmpty}
                />
              </ListItem>
              {isSuperAdmin && (
                <div className={classes.iconsBlock}>
                  {isDisabled && (
                    <IconButton type="button">
                      <FaEdit
                        size="24"
                        onClick={() => setIsDisabled(false)}
                      />
                    </IconButton>
                  )}
                  {!isDisabled && (
                    <IconButton type="submit" color="primary">
                      <FaSave size="24" />
                    </IconButton>
                  )}
                  {!isDisabled && (
                    <IconButton type="button">
                      <FaWindowClose
                        size="24"
                        onClick={() => {
                          handleReset();
                          setIsDisabled(true);
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
            </List>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}