import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldProps } from 'formik';
import { Grid, GridSize, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

type LabelDirection = 'row' | 'column';

interface StyleProps {
  readonly direction: LabelDirection;
}

type Props = FieldProps & {
  label?: string;
  labelDirection?: LabelDirection;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  md?: GridSize;
};

const useStyles = makeStyles({
  label: {
    fontSize: '14px',
  },
});

const Container = styled.div<StyleProps>`
  display: flex;

  ${(props) => {
    switch (props.direction) {
      case 'column':
        return `
          flex-direction: column;
        `;
      case 'row':
        return `
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `;
      default:
        return '';
    }
  }}
`;

export function withFormLabel(
  FormComponent: React.ComponentType<FieldProps & any>,
) {
  return function WrappedWithFormComponent({
    label,
    labelDirection = 'column',
    error = false,
    form,
    field,
    disabled,
    md,
    ...props
  }: Props): JSX.Element {
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const [onBlur, setOnBlur] =
      useState<(event: unknown) => unknown | undefined>();
    const [onFocus, setOnFocus] =
      useState<(event: unknown) => unknown | undefined>();

    const decorated = field as unknown as {
      onFocus?: (event: unknown) => unknown;
      onBlur?: (event: unknown) => unknown;
    };

    decorated.onFocus = (event?: unknown) => {
      if (!event) return;

      setFocused(true);
      if (onFocus) onFocus(event);
    };

    decorated.onBlur = (event?: unknown) => {
      if (!event) return;

      setFocused(false);
      if (onBlur) onBlur(event);
    };

    useEffect(() => {
      setOnBlur(decorated.onBlur);
      setOnFocus(decorated.onFocus);
    }, [decorated]);

    return (
      <Container direction={labelDirection}>
        {!!label && (
          <Grid item md={md ?? true}>
            <InputLabel
              required={props.required}
              error={error}
              focused={focused}
              htmlFor={field.name}
              classes={{
                root: classes.label,
              }}
              disabled={disabled}
            >
              {label}
            </InputLabel>
          </Grid>
        )}
        <Grid item md>
          <FormComponent
            field={field}
            form={form}
            // For some components overrides above are just enough,
            // but some components are ignoring focus unless specified as here:
            // @ts-ignore
            onFocus={decorated.onFocus}
            onBlur={decorated.onBlur}
            error={error}
            disabled={disabled}
            {...props}
          />
        </Grid>
      </Container>
    );
  };
}
